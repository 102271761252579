import Vue from "vue";
import Vuex from "vuex";
import axios from "../services/axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    messageModal: {
      show: false,
      title: "",
      message: "",
      styleMessage: "danger",
    },
    messageSnackbar: {
      show: false,
      message: "",
    },
    user: {
      id: null,
      name: null,
    },
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    CHANGE_MESSAGE_SNACKBAR(state, payload) {
      state.messageSnackbar.show = !state.messageSnackbar.show;
      state.messageSnackbar.message = payload;
    },
    CHANGE_MESSAGE_MODAL(state, payload) {
      state.messageModal = payload;
    },
  },
  actions: {
    action_openMessageModal(context, payload) {
      const msg = {
        show: true,
        styleMessage: payload.styleMessage,
        title: payload.title,
        message: payload.message,
      };
      if (payload.title === undefined && payload.message === undefined) {
        msg.title = "Ocorreu um erro!";
        msg.message =
          "Verifique a sua conexão com a internet e tente novamente mais tarde.";
      } else if (
        (payload.message === undefined || payload.message === null) &&
        payload.title !== undefined
      ) {
        msg.title = "Atenção !";
        msg.message = title;
      }
      context.commit("CHANGE_MESSAGE_MODAL", msg);
    },
    async action_get_banners(context, payload) {
      return await axios
        .get(`/api/banners`, payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
  },
});
