<template>
  <div>
    <div class="d-flex align-center mt-15 " style="justify-content: center">
      <h1>Financeiro</h1>
    </div>

    <div class="mt-16 pr-13 pl-13">
      <card-financeiro></card-financeiro>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Financeiro',
  components: {
    DefaultDateInput: () => import('@/components/DefaultComponents/DefaultDateInput.vue'),
    CardFinanceiro: () => import('@/components/DefaultComponents/CardFinanceiro.vue'),
  },
  data: () => ({}),
  methods: {
    voltar() {
      this.$router.back()
    }
  }

}
</script>

<style>

</style>
