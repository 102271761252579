<template>
  <div>
    <v-row class="cor-cereja">
      <h2 class="cor-branca ma-4">Siga-nos nas redes sociais e confira as novidades da Creative Learning Center</h2>
      <v-btn
          href="https://pt-br.facebook.com/creativelearningpg/"
          icon
          color="white"
          class="mr-5 ml-2"
      >
        <v-icon class="tamanho-icone-rodape">mdi-facebook</v-icon>
      </v-btn>
      <v-btn
          href="https://www.instagram.com/creativelearningpg/?igshid=telw55bvoej3"
          icon
          color="white"
          class="mr-5"
      >
        <v-icon class="tamanho-icone-rodape">mdi-instagram</v-icon>
      </v-btn>
      <v-btn
          href="https://api.whatsapp.com/send?phone=5542999613608&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Escola%20Cretive."
          icon
          color="white"
      >
        <v-icon class="tamanho-icone-rodape">mdi-whatsapp</v-icon>
      </v-btn>
    </v-row>
    <v-row class="align-content-center">
      <p color="white" class="mb-10 mt-10">Copyright©2014-{{ anoCorrente() }}, Schaffen IT. Todos os direitos
        reservados. </p>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Rodape',

  data: () => ({
  }),
  methods: {
    anoCorrente() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style>
.cor-cereja {
  background-color: #EF4056;
  justify-content: center;
  align-items: center;
}

.cor-branca {
  color: white;
}

.align-content-center {
  justify-content: center;
}

.tamanho-icone-rodape{
  font-size: 40px !important;
}
</style>
