<template>
  <div class="d-flex flex-column align-center">
    <div class="adiciona-button">
        <!--<v-col v-show="!adicionar" cols="12" class="my-2 align-center tam" style="padding:0px">
          <v-btn v-on:click="adicionarUsuario" color="#98C238" dark>Adicionar Usuário</v-btn>
        </v-col>-->
        <v-col cols="12" class="align-center" style="padding:0px">
          <h1 class="mb-3">Usuários</h1>
        </v-col>
    </div>
    <div v-if="adicionar" style="width:85%">
      <v-row >
        <v-col cols="12" sm="2" md="2">
           <v-text-field
            label="Código"
            v-model="editedItem.codigo"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="10" md="10">
           <v-text-field
            label="Nome"
            v-model="editedItem.nome"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
           <v-text-field
            label="Login"
            v-model="editedItem.usuario"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
           <v-text-field
            label="E-mail"
            v-model="editedItem.email"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
         <v-col cols="12" class="align-center" style="padding:0px">
            <h1 class="mb-3">Alunos</h1>
         </v-col>
      </v-row>
      <v-row>
         <v-col cols="12" sm="8" md="8" style="margin:auto">
            <v-text-field
               label="Nome"
               v-model="editedItemAluno.name"
            ></v-text-field>
         </v-col>
      </v-row>
      <v-row>
         <v-col cols="12" sm="8" md="8" style="margin:auto">
            <v-select
              :items="salas"
              label="Sala"
              item-text="nome"
              item-value="classroom_id"
              v-model="editedItemAluno.sala"
              solo
          ></v-select>
         </v-col>
      </v-row>

      <v-row>
         <v-col cols="12" sm="2" md="2" style="margin:auto">
          <DefaultButton :text="'Adicionar'"
                         :disable="(this.editedItemAluno.name != '' && this.editedItemAluno.sala != '')?false:true"
                         @click.native="salvarNovoAluno()"></DefaultButton>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="8" md="8" style="margin:auto">
          <v-data-table
               :headers="headers_Alunos"
               :items="alunos"
               hide-default-footer
               :items-per-page="alunos.length"
               style="margin-bottom:20px"
               class="elevation-1 estilo-tabela"
               :loading="loadingAlunos"
               loading-text="Carregando...">

                  <template v-slot:no-data>
                     <h4 class="mb-1 mt-1">Lista de Alunos vazia</h4>
                  </template>

                  <template v-slot:top>
                    <v-dialog v-model="dialogDeleteAluno" max-width="550px">
                      <v-card>
                        <v-card-title class="headline">Tem certeza que quer excluir esse aluno?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDeleteAluno">Cancel</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteAlunoConfirm">OK</v-btn>
                          <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>     

                    <template v-slot:item="{ item }">
                      {{terminaLoadingAlunos()}}
                      <tr v-show="item.parent_id == editedItem.codigo">
                        <td>{{item.name}}</td>
                        <td>{{item.classroom.nome}}</td>
                        <td>
                          <v-icon
                          small
                          class="mr-2 icon_acoes"
                          @click="editAluno(item)">
                          mdi-pencil
                          </v-icon>
                          <v-icon
                          small
                          class="mr-2 icon_acoes"
                          @click="deleteAluno(item)">
                          mdi-delete
                          </v-icon>
                        </td>
                      </tr>
                    </template>
               </v-data-table>
            </v-col>
      </v-row>

      <v-row class="mb-5">
        <v-col cols="12" sm="6" md="6">
          <DefaultButton :cor="1" :text="'Cancelar'" @click.native="adicionarUsuario()"></DefaultButton>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <DefaultButton :text="(editar == true)?'Salvar Edição':'Adicionar'"
                         :disable="(this.editedItem.codigo != null && this.editedItem.nome != null && this.editedItem.usuario != null && this.editedItem.email != null)?false:true"
                         @click.native="salvarNovoUsuario()"></DefaultButton>
        </v-col>
      </v-row>
    </div>
    <div>
      <DefaultDialog
                  :dialog="dialogAluno"
                  :title="(editar != true)?'Adicionado com sucesso':'Editado com sucesso'"
                  :text="(editar != true)?'O aluno foi adicionado com sucesso.':'O aluno foi editado com sucesso.'"
                  @update:closeDialog="dialogAluno = $event"
                  />
    </div>
    <v-data-table
        :headers="headers"
        :items="usuarios"
        :page.sync="page"
        :items-per-page="12"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="codigo"
        show-expand
        v-show="!adicionar"
        hide-default-footer
        class="elevation-1 estilo-tabela"
        @page-count="pageCount = $event"
        :loading="loadingUsuarios"
        loading-text="Carregando...">

        <template v-slot:top>
                    <v-dialog v-model="dialogDelete" max-width="550px">
                      <v-card>
                        <v-card-title class="headline">Tem certeza que quer excluir esse usuário?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                          <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                      <v-dialog v-model="dialogSenha" max-width="550px">
                        <v-card>
                          <v-card-title class="headline">Tem certeza que quer redefinir esta senha?</v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeResetSenha">Cancel</v-btn>
                            <v-btn color="blue darken-1" text @click="editSenhaConfirm">OK</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <table style="width:100%">
              <tr>
                <div v-for="(value, name) of item.estudantes">
                  <td style="width:500px"><b>Nome : </b> {{item.estudantes[name].name}}</td>
                  <td><b>Sala : </b> {{item.estudantes[name].classroom.nome}}</td>
                </div>
              </tr>
            </table>
          </td>

        </template>

        <template v-slot:item.actions="{ item }">
          {{terminaLoadingUsuarios()}}
          <v-icon
              small
              class="mr-2 icon_acoes"
              @click="editItem(item)">
              mdi-pencil
          </v-icon>
          <v-icon
              small
              class="mr-2 icon_acoes"
              @click="deleteItem(item)">
              mdi-delete
          </v-icon>
          <v-icon
              small
              class="icon_acoes"
              @click="editSenha(item)">
              mdi-lock-open-outline
          </v-icon>
        </template>

        <template v-slot:no-data>
          <h4 class="mb-1 mt-1">Lista de usuários vazia</h4>
        </template>
        
    </v-data-table>
    <div v-show="!adicionar" class="text-center pt-2">
      <v-pagination
          class="pagination"
          v-model="page"
          :length="pageCount"
      ></v-pagination>
    </div>
    <DefaultDialog
        :dialog="dialog"
        :title="(editar != true)?'Adicionado com sucesso':'Editado com sucesso'"
        :text="(editar != true)?'O usuário foi adicionado com sucesso.':'O usuário foi editado com sucesso.'"
        @update:closeDialog="dialog = $event"
    />
  </div>
</template>

<script>
import axios from "../../services/axios";

export default {
  name: 'AddUsuarios',
  components: {
    DefaultButton: () => import("@/components/DefaultComponents/DefaultButton"),
    DefaultDialog: () => import("@/components/DefaultComponents/DefaultDialog"),
  },
  data() {
    return {
      loadingUsuarios: false,
      loadingAlunos: false,
      expanded: [],
      singleExpand: true,
      dialog: false,
      dialogAluno: false,
      dialogSenha: false,
      dialogDelete: false,
      dialogDeleteAluno: false,
      adicionar: false,
      adicionarAluno: false,
      editar: false,
      editarAluno: false,
      page: 1,
      pageCount: 0,
      headers: [
        {text: 'Cod Usuário', value:'codigo',},
        {text: 'Cod Financeiro', value: 'pes_codigo',},
        {text: 'Nome',value: 'nome',},
        {text: 'Login', value: 'usuario', sortable: true,},
        {text: 'E-mail', value: 'email', sortable: true,},
        {text: 'Alunos', value: 'data-table-expand', sortable: false,},
        {text: 'Ações', value: 'actions', sortable: false,},
      ],
      headers_Alunos:[
        {text: 'Nome',value: 'name',},
        {text: 'Sala',value: 'classroom.nome',},
        {text: 'Ações', value: 'actions',sortable: false,},
      ],
      usuarios: [],
      alunos: [],
      salas:[],
      editedIndex: -1,
      editedIndexAluno: -1,
      editedItem: {
        nome: '',
        usuario: '',
        email: '',
        codigo: '',
      },
      defaultItem: {
        nome: '',
        usuario: '',
        email: '',
        codigo: '',
      },
      editedItemAluno: {
        cod_responsavel: '',
        name: '',
        codigo: '',
        sala: '',
      },
      defaultItemAluno: {
        cod_responsavel: '',
        name: '',
        codigo: '',
        sala: '',
      },
    }
  },
  mounted() {
    this.inicializa()
  },
  methods: {
    inicializa() {
      this.loadingUsuarios = true,
      axios
        .get("/api/usuarios?with[]=estudantes")
        .then((response) => {
          this.usuarios = response.data.data.map((usuarios) => ({
            ...usuarios, // Association, same as Object.assign
          }));
          if(response.data.data.length == 0)this.terminaLoading();
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("/api/alunos?with[]=classrooms")
        .then((response) => {
          this.alunos = response.data.data.map((alunos) => ({
            ...alunos, // Association, same as Object.assign
          }));
          if(response.data.data.length == 0)this.terminaLoading();
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("/api/salas")
        .then((response) => {
          this.salas = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    adicionarUsuario() {
      this.loadingAlunos = true,
      this.adicionar = !this.adicionar;
      if (this.editar == true) this.editar = false;
      this.editedItem = this.defaultItem;

      this.adicionarAluno = false;
      this.editarAluno = false;
      this.editedItemAluno= {
        cod_responsavel: '',
        name: '',
        codigo: '',
        sala: '',
      };
    },
    salvarNovoUsuario() {
      if (this.editedItem.codigo != null && this.editedItem.nome != null && this.editedItem.usuario != null && this.editedItem.email != null) {
        this.dialog = true
        const dados = {
          "id": this.editedItem.codigo,
          "name": this.editedItem.nome,
          "username": this.editedItem.usuario,
          "email": this.editedItem.email,
        };
        if (this.editar != true) {
          axios
            .post(
              "/api/usuarios",
              dados,
              {
                headers: {},
              }
            )
            .then(() => {
              this.inicializa();
            })
            .catch((error) => {
              console.log(error);
            });
            this.adicionar = !this.adicionar;
        } else {
          axios
            .put("/api/usuarios/" +
              this.editedItem.codigo,
              dados,
              {headers: {},}
            )
            .then(() => {
              this.inicializa();
            })
            .catch((error) => {
              console.log(error);
            });
          this.adicionar = !this.adicionar;
        }
      }
    },
    salvarNovoAluno(){
      if (this.editedItemAluno.name != '' && this.editedItemAluno.sala != '') {
        this.dialogAluno = true
          const dados = [{
            "name": this.editedItemAluno.name,
            "classroom_id" : this.editedItemAluno.sala,
            "parent_id": this.editedItem.codigo,
            'id': this.editedItemAluno.codigo,
            }];
        if (this.editarAluno != true) {
          axios
            .post(
              "/api/alunos",
              dados,
              {
                headers_alunos: {},
              }
            )
            .then(() => {
              this.inicializa();
            })
            .catch((error) => {
              console.log(error);
            });
            this.adicionarAluno = !this.adicionarAluno;
            this.editedItemAluno= {
            cod_responsavel: '',
            name: '',
            codigo: '',
            sala: '',
          }
        } else {
          axios
            .put("/api/alunos/" +
              this.editedItemAluno.codigo,
              dados,
              {headers_Alunos: {},}
            )
            .then(() => {
              this.inicializa();
            })
            .catch((error) => {
              console.log(error);
            });
          this.adicionarAluno = !this.adicionarAluno;
          
          this.editedItemAluno= {
            cod_responsavel: '',
            name: '',
            codigo: '',
            sala: '',
          }
        };
      }
    },
    deleteItem(item) {
      console.log("del")
      this.editedIndex = this.usuarios.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteAluno(item) {
      this.editedIndexAluno = this.alunos.indexOf(item)
      this.editedItemAluno = Object.assign({}, item)
      this.dialogDeleteAluno = true
    },
    editSenha(item) {
      this.editedIndex = this.usuarios.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogSenha = true
    },
    editSenhaConfirm() {
      this.dialogSenha = false
      const dados = {
          "user_id": this.editedItem.codigo,
        };
          axios
            .post("/api/usuarios/reset-password",
              dados,
            )
            .then(() => {
              this.inicializa();
            })
            .catch((error) => {
              console.log(error);
            });
    },
    editItem(item) {
      if (this.adicionar == false) this.adicionar = true
      this.editedItem.codigo = item.codigo
      this.editedItem.nome = item.nome
      this.editedItem.usuario = item.usuario
      this.editedItem.email = item.email
      this.alunos = item.estudantes
      this.editar = true
    },
    editAluno(aluno) {
      if (this.adicionarAluno == false) this.adicionarAluno = true
      this.editedItemAluno.name = aluno.name
      this.editedItemAluno.codigo = aluno.codigo
      this.editedItemAluno.sala = aluno.classroom.classroom_id
      this.editedItemAluno.cod_responsavel = aluno.parent_id
    
      this.editarAluno = true
    },
    deleteItemConfirm() {
      axios
          .delete(
              "/api/usuarios/" +
              this.usuarios[this.editedIndex].codigo
          )
          .then(() => {
          })
          .catch((error) => {
            console.log(error);
          });
      this.usuarios.splice(this.editedIndex, 1)
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteAlunoConfirm() {
      axios
          .delete(
              "/api/alunos/" +
              this.alunos[this.editedIndexAluno].codigo
          )
          .then(() => {
          })
          .catch((error) => {
            console.log(error);
          });
      this.alunos.splice(this.editedIndexAluno, 1)
      this.closeDeleteAluno()
    },
    closeDeleteAluno() {
      this.dialogDeleteAluno = false
      this.$nextTick(() => {
        this.editedItemAluno = Object.assign({}, this.defaultItemAluno)
        this.editedIndexAluno = -1
      })
    },
    closeResetSenha() {
      this.dialogSenha = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    terminaLoadingAlunos(){
      this.loadingAlunos = false
    },
    terminaLoadingUsuarios(){
      this.loadingUsuarios = false
    },
  },
}
</script>

<style>
.estilo-tabela {
  width: 100% !important;
}

.pagination > ul > li > button > i {
  font-size: 45px !important;
}

.status{
  width:100%;
  justify-content: center
}

.mdi-chevron-down{
  color: #ef4056 !important;
  height: 25px;
  width: 25px;
  font-size: 50px !important;
}

</style>