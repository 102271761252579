<template>
  <div class="d-flex justify-space-around flex-wrap">
    <card-selection
        v-for="(botao,i) in botoes"
        :title="botao.title"
        :icon="botao.icon"
        @click.native="navigateTo(botao.goTo)"></card-selection>
  </div>
</template>

<script>
export default {
  name: 'Principal',
  components: {
    CardSelection: () => import("@/components/CardSelection"),
  },
  data: () => ({
    adicionar: false,
    botoes: [
      {
        title: 'Calendário Escolar',
        icon: 'mdi-calendar-blank-multiple',
        goTo: ''
      }, {
        title: 'Cardápio',
        icon: 'mdi-silverware',
        goTo: ''
      }, {
        title: 'Comunicados',
        icon: 'mdi-newspaper-variant',
        goTo: 'comunicados'
      }, {
        title: 'Fotos',
        icon: 'mdi-image-album',
        goTo: 'albuns'
      },
    ]
  }),
  methods: {
    navigateTo(where) {
      console.log("entrou")
      this.$router.push({path: where})
    }
  }
}
</script>
