import { render, staticRenderFns } from "./Diferenciais.vue?vue&type=template&id=21e6d05a&"
import script from "./Diferenciais.vue?vue&type=script&lang=js&"
export * from "./Diferenciais.vue?vue&type=script&lang=js&"
import style0 from "./Diferenciais.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VCard,VExpandTransition,VRow,VSheet,VSlideGroup,VSlideItem})
