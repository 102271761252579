<template>
  <div
      class="mx-auto cor-cinza"
      elevation="8"
      max-width="800"
  >
    <div class="custom-shape-divider-top-1611323652">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            class="shape-fill"></path>
      </svg>
    </div>

    <div class="alinha-itens-escola mr-16 ml-16 mt-15 mb-15">
      <img :src="imagem" width="450px" class="imagem"/>
      <div class="pa-10 centraliza-itens">
        <h1 class="titulo-propaganda cor-melancia tamanho-fonte">Escola Creative Learning Center</h1>
        <h3 class="cor-melancia">Rua Octávio de Carvalho, 640, Jardim Carvalho | Ponta Grossa - PR
        </h3>
        <div class="d-flex align-center mt-2">
          <v-btn
              href="tel:+554230865350"
              icon
              color="#FF3B3F"
              class="mr-4"
              x-small
          >
            <v-icon class="tamanho-icone-rodape">mdi-phone</v-icon>
          </v-btn>
          <h3 class="cor-melancia"> (42) 3086-5350</h3>
        </div>
        <div class="d-flex align-center mt-2">
          <v-btn
              href="https://api.whatsapp.com/send?phone=5542999613608&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Escola%20Cretive."
              icon
              color="#FF3B3F"
              class="mr-4"
              x-small
          >
            <v-icon class="tamanho-icone-rodape">mdi-whatsapp</v-icon>
          </v-btn>
          <h3 class="cor-melancia"> (42) 99961-3608</h3>
        </div>
        <div class="alinha-itens-escola mt-5">
          <v-icon class="tamanho-icone">mdi-check</v-icon>
          <p class="title">Educação Infantil</p>
          <p class="title font-italic"> (Turmas do Infantil 1 ao 5)</p>
        </div>
        <div class="alinha-itens-escola">
          <v-icon class="tamanho-icone">mdi-check</v-icon>
          <p class="title">Ensino Fundamental</p>
          <p class="title font-italic"> (Turmas do 1º ao 5º ano)</p>
        </div>
      </div>
    </div>

    <div class="custom-shape-divider-bottom-1611252959">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path
            d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
            opacity=".25" class="shape-fill"></path>
        <path
            d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
            opacity=".5" class="shape-fill"></path>
        <path
            d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
            class="shape-fill"></path>
      </svg>
    </div>
  </div>
</template>

<script>
import imagem_escola from '@/assets/escola_creative.png'

export default {
  name: 'Unidade',

  data: () => ({
    imagem: imagem_escola
  }),
}
</script>

<style>
.cor-cinza {
  background-color: #dfe6e9;
}

.cor-melancia {
  color: #ff3b3f;
}

.centraliza-itens {
  display: flex;
  flex-direction: column;

  align-items: center;
}

.imagem {
  border-radius: 15px;
}

.alinha-itens-escola {
  display: flex;
  justify-content: space-around !important;
  align-items: center;
  flex-wrap: wrap;
}

.tamanho-icone {
  font-size: 40px !important;
}

.v-application p {
  margin-bottom: 0px;
}

.tamanho-fonte {
  font-size: 40px;
}

.tamanho-fonte-mobile {
  font-size: 20px;
}

.custom-shape-divider-bottom-1611252959 {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1611252959 svg {
  position: relative;
  display: block;
  width: calc(144% + 1.3px);
  height: 40px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1611252959 .shape-fill {
  fill: #9CCB47;
}

.custom-shape-divider-top-1611323652 {
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-top-1611323652 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 44px;
}

.custom-shape-divider-top-1611323652 .shape-fill {
  fill: #FFFFFF;
}
</style>
