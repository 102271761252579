<template>
  <div>
    <div class="d-flex align-center mt-15 " style="justify-content: center">
      <v-btn class="mx-2 mr-5" fab dark color="#EF4056" @click="voltar()">
        <v-icon dark>
          mdi-arrow-left-thick
        </v-icon>
      </v-btn>
      <h1>Comunicados</h1>
    </div>

    <default-date-input></default-date-input>
    <div
      class="mt-16 pr-13 pl-13"
      v-for="(comunicado, i) in comunicados"
      :key="i"
    >
      <card-comunicados
        :titulo="comunicado.titulo"
        :descricao="comunicado.descricao"
        :nome_evento="comunicado.nome_evento"
        :codigo_tipo_evento="comunicado.codigo_tipo_evento"
        :data_criacao="comunicado.criado_em"
      ></card-comunicados>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Comunicados",
  components: {
    DefaultDateInput: () =>
      import("@/components/DefaultComponents/DefaultDateInput.vue"),
    CardComunicados: () =>
      import("@/components/DefaultComponents/CardComunicados.vue"),
  },
  data() {
    return {
      comunicados: {
        default: [],
      },
    };
  },
  mounted() {
    this.inicializa();
  },
  methods: {
    voltar() {
      this.$router.back();
    },
    inicializa() {
      axios
        .get("/api/comunicados")
        .then((response) => {
          console.log("eventos", response.data.data);
          this.comunicados = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
