import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import AddBanners from '../views/admin/AddBanners.vue'
import AddAlbuns from '../views/admin/AddAlbuns.vue'
import Principal from '../views/home_pais/Principal.vue'
import Atendimentos from '../views/home_pais/Atendimentos.vue'
import Financeiro from '../views/home_pais/Financeiro.vue'
import Comunicados from '../components/home_pais/Comunicados.vue'
import Albuns from '../components/home_pais/Albuns.vue'
import AddDiferenciais from '../views/admin/AddDiferenciais.vue'
import AdmAtendimentos from '../views/admin/AdmAtendimentos.vue'
import AdmComunicados from "@/views/admin/AdmComunicados";
import AddCalendario from "@/views/admin/AddCalendario";
import AddCardapio from "@/views/admin/AddCardapio";
import AddUsuario from "@/views/admin/AddUsuario";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/admin',
        name: 'Admin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue'),
        children: [
            {
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                path: '/banners',
                component: AddBanners
            },
            {
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                path: '/diferenciais',
                component: AddDiferenciais
            },
            {
                path: '/albuns',
                component: AddAlbuns
            },
            {
                path: '/atendimentos',
                component: AdmAtendimentos
            },
            {
                path: '/comunicados',
                component: AdmComunicados
            },
            {
                path: '/calendario',
                component: AddCalendario
            },
            {
                path: '/cardapio',
                component: AddCardapio
            },
            {
                path: '/usuario',
                component: AddUsuario
            },
        ]
    },
    {
        path: '/area-pais',
        name: 'HomeAreaPais',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/HomeAreaPais.vue'),
        children: [
            {
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                path: 'home',
                component: Principal,
            },
            {
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                path: 'comunicados',
                component: Comunicados
            },
            {
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                path: 'albuns',
                component: Albuns
            },
            {
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                path: 'atendimentos',
                component: Atendimentos
            },
            {
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                path: 'financeiro',
                component: Financeiro
            }
        ]
    }
]

const router = new VueRouter({
//    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
