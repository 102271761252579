<template>
  <div class="d-flex flex-column align-center">
    <div class="adiciona-button">
        <v-col v-show="!adicionar" cols="12" class="my-2 align-center tam" style="padding:0px">
          <v-btn v-on:click="adicionarAlbum" color="#98C238" dark>Adicionar Álbum</v-btn>
        </v-col>
        <v-col cols="12" class="align-center" style="padding:0px">
          <h1 class="mb-3">Álbuns</h1>
        </v-col>
    </div>
    <div v-if="adicionar" style="width:85%">
      <v-row class="mb-5">
        <v-col cols="12" sm="12" md="12">
          <v-text-field
              label="Título"
              v-model="editedItem.nome"
          ></v-text-field>
        </v-col>
      </v-row>
        <DefaultDatePicker
            :label="'Data do evento'"
            :data="(editedItem.data_evento == '')? '': formataData(editedItem.data_evento)"
            @update:returnDate="editedItem.data_evento = $event"/>
      <v-row class="mb-5">
        <v-col cols="12" sm="12" md="12">
          <v-text-field
              label="Link do album"
              v-model="editedItem.link"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-select
              :items="salas"
              label="Sala"
              item-text="nome"
              item-value="classroom_id"
              v-model="editedItem.id_sala"
              solo
          ></v-select>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <DefaultDocumentInput
              text="Anexar imagem para o album"
              :required="true"
              type="album"
              module="editedItem.capa"
              reqText="Selecione o arquivo"
              @update:returnFile="editedItem.capa = $event"
          />
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <DefaultButton :cor="1" :text="'Cancelar'" @click.native="adicionarAlbum()"></DefaultButton>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <DefaultButton :text="(editar == true)?'Salvar Edição':'Adicionar'"
                         :disable="(this.editedItem.nome != '' && this.editedItem.link != '' && this.editedItem.capa !=  '' && this.editedItem.data_evento != null && this.editedItem.sala != '')?false:true"
                         @click.native="salvarNovoAlbum()"></DefaultButton>
        </v-col>
      </v-row>
    </div>
    <v-data-table
        :headers="headers"
        :items="albuns"
        :page.sync="page"
        :items-per-page="10"
        hide-default-footer
        v-show="!adicionar"
        class="elevation-1 estilo-tabela"
        @page-count="pageCount = $event"
        :loading="loading"
        loading-text="Carregando...">
        <template v-slot:item.capa="{item}">
          <div style="margin-top:5px">
            <img :src="'data:image/png;base64,' + item.capa"  width="140" height="100"></img>
          </div>
        </template>
        <template v-slot:item.data_evento="{item}">
          <span>
            {{formataData(item.data_evento)}}
          </span>
        </template>
        <template v-slot:item.link="{item}">
          <div>
            <a :href="item.link" target="_blank">Link para {{item.nome}}</a>
          </div>
        </template>
      <template v-slot:top>
        <v-dialog v-model="dialogDelete" max-width="550px">
          <v-card>
            <v-card-title class="headline">Tem certeza que quer excluir esse album?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.actions="{ item }">
        {{terminaLoading()}}
        <v-icon
            small
            class="mr-2 icon_acoes"
            @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            class="icon_acoes"
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <h4 class="mb-1 mt-1">Lista de albuns vazia</h4>
      </template>
    </v-data-table>


    <div v-show="!adicionar" class="text-center pt-2">
      <v-pagination
          class="pagination"
          v-model="page"
          :length="pageCount"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import axios from "../../services/axios";
import moment from "moment";

export default {
  name: 'AddAlbuns',
  components: {
    DefaultDocumentInput: () => import("@/components/DefaultComponents/DefaultDocumentInput"),
    DefaultDatePicker: () => import("@/components/DefaultComponents/DefaultDatePicker"),
    DefaultButton: () => import("@/components/DefaultComponents/DefaultButton"),
  },
  data() {
    return {
      loading: false,
      dialog: false,
      dialogDelete: false,
      adicionar: false,
      editar: false,
      page: 1,
      pageCount: 0,
      headers: [
        {text: 'Nome',sortable: false,value: 'nome',},
        {text: 'Imagem capa', value: 'capa', sortable: false,},
        {text: 'Data evento', value: 'data_evento', sortable: true,},
        {text: 'Sala', value: 'nome_sala', sortable: false,},
        {text: 'Link', value: 'link', sortable: false,},
        {text: 'Ações', value: 'actions', sortable: false},
      ],
      albuns: [],
      salas:[],
      editedIndex: -1,
      editedItem: {
        codigo: -1,
        nome: '',
        capa: '',
        data_evento: '',
        link: '',
        id_sala: '',
      },
      mostra_adicionar: 1,
    }
  },
  mounted() {
    this.inicializa()
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    inicializa() {
      this.loading = true,
      axios
        .get("/api/portal/albuns")
        .then((response) => {
          this.albuns = response.data.data;
          if(response.data.data.length == 0)this.terminaLoading();
        })
        .catch((error) => {
        });
      axios
        .get("/api/salas")
        .then((response) => {
          this.salas = response.data.data;
        })
        .catch((error) => {
        });
    },
    adicionarAlbum() {
      this.adicionar = !this.adicionar;
      if (this.editar == true) this.editar = false
      this.editedItem = {
          codigo: -1,
          nome: '',
          capa: '',
          data_evento: '',
          link: '',
          id_sala: '',
        };
    },
    salvarNovoAlbum() {
        if (this.editedItem.nome != '' && this.editedItem.link != '' && this.editedItem.capa !=  null && this.editedItem.data_evento != '' && this.editedItem.sala != '') {
        const dados = {
          "name": this.editedItem.nome,
          "file_name": this.editedItem.capa[0].name,
          "cover": this.editedItem.capa[0].base64,
          "classroom_id": this.editedItem.id_sala,
          "link": this.editedItem.link,
          "date":this.editedItem.data_evento,
        }
        if (this.editar != true) {
          axios
            .post("/api/albuns", dados, {
              headers: {},
            })
            .then((response) => {
              this.inicializa();
            })
            .catch((error) => {
              console.log(error);
            });
          this.adicionar = !this.adicionar;
        } else {
          axios
            .put(
              "/api/albuns/" +
                this.editedItem.codigo,
              dados,
              {
                headers: {},
              }
            )
            .then((response) => {
              this.inicializa();
            })
            .catch((error) => {
              console.log(error);
            });
          this.adicionar = !this.adicionar;
        }
        this.editedItem = {
          codigo: -1,
          nome: '',
          capa: '',
          data_evento: '',
          link: '',
          id_sala: '',
        };
      }
    },
    deleteItem(item) {
      this.editedIndex = this.albuns.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    editItem(item) {
      if (this.adicionar == false) this.adicionar = true
      this.editedItem.codigo = item.codigo
      this.editedItem.nome = item.nome
      this.editedItem.capa = item.capa
      this.editedItem.data_evento = item.data_evento
      this.editedItem.id_sala = item.id_sala
      this.editedItem.link = item.link
      this.editar = true
    },
    deleteItemConfirm() {
      axios
        .delete(
          "/api/albuns/" +
            this.albuns[this.editedIndex].codigo
        )
        .then((response) => {
        })
        .catch((error) => {
          console.log(error);
        });
      this.albuns.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem)
        this.editedIndex = -1
      })
    },
    formataData(data_evento){
      return moment(String(data_evento)).format('DD/MM/YYYY')
    },
    terminaLoading(){
      this.loading = false
    },
  },
}
</script>

<style>
.estilo-tabela {
  width: 100% !important;
}

.pagination > ul > li > button > i {
  font-size: 45px !important;
}

</style>
