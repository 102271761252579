import configAxios from "axios";
import router from "../router";
import store from "../store";

const apiInstance = configAxios.create({
  //baseURL: "https://creativeapi.schaffenlab.com",
  baseURL: process.env.VUE_APP_API_URL,
});

// apiInstance.interceptors.request.use((config) => {
//     configAxios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// });

apiInstance.interceptors.request.use((config) => {
  const bearerToken = localStorage.getItem("access_token");

  // if (
  //     bearerToken
  //     || bearerToken !== 'undefined'
  //     || bearerToken !== ''
  //     || bearerToken != null
  // ) {
  //     config.headers.Authorization = `Bearer ${bearerToken}`;
  // }
  config.headers.common["Access-Control-Allow-Origin"] = "*";
  config.headers.Authorization = `Bearer ${bearerToken}`;
  return config;
});

apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("veja o erro:", error);
    const { response } = error;
    // if (response?.status === 401 || response?.status === 403 || error?.message === 'Network Error') {
    //     localStorage.removeItem('token');
    //     localStorage.removeItem('access_token');
    //     localStorage.removeItem('user_id');
    //     localStorage.clear();
    //     router.push('/login');
    // }
    let msg = {
      show: true,
      title: "Atenção",
      styleMessage: "danger",
      message:
        response.data.message === "Call to a member function toArray() on null"
          ? "URL não encontrada."
          : response.data?.message || "Ocorreu um erro. Tente novamente",
    };
    store.dispatch("action_openMessageModal", msg);
  }
);

export default apiInstance;
