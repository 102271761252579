<template>
  <div
      class="mx-auto cor-verde"
      elevation="8"
      max-width="800"
  >
    <div class="custom-shape-divider-top-1611254985">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
      </svg>
    </div>

    <div v-if="tamanhoTela > 800" class="alinhar-propaganda">
      <div class="pa-10 alinhar-itens-propaganda">
        <h1 class="titulo-propaganda">Certificação CAMBRIDGE, só a Creative tem!</h1>
        <p class="title cor-branca mt-6">O Certificado Cambridge é uma qualificação de idioma que prova que seu detentor
          possui as
          habilidades
          linguísticas para viver, estudar e trabalhar independentemente em um país de língua inglesa. Obter tal
          certificado mostra ao mundo que seu titular dominou o inglês em um nível excepcional. Seu filho ficará gigante
          com a certificação de uma das universidades mais prestigiadas e reconhecidas no mundo todo.</p>
        <img class="imagem-cambridge mt-4" src="https://pinelakeelementary.com/files/2020/02/CAMBRIDGE-INTERNATIONAL-SCHOOL-LOGO-4C.jpg" width="350px"/>
      </div>
      <img :src="banner" width="350px"/>
    </div>

    <div v-if="tamanhoTela <= 800" class="alinhar-propaganda">
      <div class="pa-10 alinhar-itens-propaganda-mobile">
        <h1 class="titulo-propaganda">Certificação CAMBRIDGE, só a Creative tem!</h1>
        <p class="title cor-branca mt-6">O Certificado Cambridge é uma qualificação de idioma que prova que seu detentor
          possui as
          habilidades
          linguísticas para viver, estudar e trabalhar independentemente em um país de língua inglesa. Obter tal
          certificado mostra ao mundo que seu titular dominou o inglês em um nível excepcional. Seu filho ficará gigante
          com a certificação de uma das universidades mais prestigiadas e reconhecidas no mundo todo.</p>
        <img class="imagem-cambridge mt-4" src="https://pinelakeelementary.com/files/2020/02/CAMBRIDGE-INTERNATIONAL-SCHOOL-LOGO-4C.jpg" width="350px"/>
      </div>
      <img :src="banner" width="350px"/>
    </div>

    <div class="custom-shape-divider-bottom-1611254584">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
              class="shape-fill"></path>
      </svg>
    </div>
  </div>
</template>

<script>
import imagem_cambridge from '@/assets/cambridge.png'

export default {
  name: 'PrimeiraPropaganda',

  data: () => ({
    banner: imagem_cambridge,
    tamanhoTela: window.innerWidth,
  }),
}
</script>
<style>
.titulo-propaganda {
  font-family: 'hamburadul-regular';
  display: flex;
  justify-content: center;
  color: white;
}

.cor-vermelha {
  background-color: #ef4056;
}

.cor-laranja {
  background-color: #ea5c2d;
}

.cor-verde {
  background-color: #98a956;
}

.cor-branca {
  color: white;
  text-align: justify;
}

.alinhar-propaganda {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.alinhar-itens-propaganda{
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 75%;
}
.alinhar-itens-propaganda-mobile{
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 95%;
}

.imagem-cambridge{
  border-radius: 15px;
}

.custom-shape-divider-top-1611254985 {
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1611254985 svg {
  position: relative;
  display: block;
  width: calc(131% + 1.3px);
  height: 33px;
}

.custom-shape-divider-top-1611254985 .shape-fill {
  fill: #F4BB2B;
}

.custom-shape-divider-bottom-1611254584 {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1611254584 svg {
  position: relative;
  display: block;
  width: calc(131% + 1.3px);
  height: 33px;
}

.custom-shape-divider-bottom-1611254584 .shape-fill {
  fill: #FFFFFF;
}
</style>
