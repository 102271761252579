<template class="fundo">
  <div class="flex align-center fundo mr-0 justify-space-around">
    <div id="logo">
      <v-img
        alt="Vuetify Logo"
        class="shrink ml-5"
        contain
        src="@/assets/logo.png"
        transition="scale-transition"
        width="300"
      />
    </div>
    <div></div>
    <div>
      <v-card>
        <v-form
          v-model="valid"
          ref="form"
          lazy-validation
          @keyup.native.enter="submit"
        >
          <v-container>
            <v-layout column>
              <v-text-field
                label="Email"
                v-model="username"
                type="text"
                required
                :error-messages="'Login invalido'"
              ></v-text-field>

              <v-text-field
                label="Password"
                v-model="password"
                type="password"
                required
                :error-messages="'Password invalido'"
              ></v-text-field>
            </v-layout>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-flex offset-xs2 mb-2>
            <v-btn color="primary" @click="submit" :disabled="!valid"
              >Entrar</v-btn
            >
          </v-flex>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  components: {},
  data: () => ({
    diferenciais: [],
    username: "",
    password: "",
    valid: false,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
  }),
  methods: {
    submit() {
      const formData = new FormData();
      formData.append('username', this.username);
      formData.append('password', this.password);

      axios
        .post(process.env.VUE_APP_API_URL + "/api/login", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          const token = response.data.access_token;
          localStorage.setItem("access_token", token);

          this.$router.push("Admin");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.fundo {
  height: 100vh;
  width: 100%;
  background-image: url("../assets/background.png");
  background-position: center;
  background-size: cover;
  padding-left: 5%;
  padding-right: 5%;
  box-sizing: border-box;
  position: relative;
}
.v-application .ml-5 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.container {
  width: 330px;
}

.flex {
  max-width: 2000px !important;
}
</style>
