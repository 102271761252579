<template>
  <v-carousel cycle height="auto" hide-delimiters>
    <v-carousel-item
      v-for="(banner_p, i) in banners_p"
      v-if="tamanhoTela <= 800"
      :key="i"
      :src="banner_p.imagem"
      class="tamanho"
    />
    <v-carousel-item
      v-for="(banner_m, j) in banners_m"
      v-if="tamanhoTela > 800 && tamanhoTela < 1200"
      :key="j"
      :src="banner_m.imagem"
      class="tamanho"
    />
    <v-carousel-item
      v-for="(banner_g, k) in banners_g"
      v-if="tamanhoTela >= 1200"
      :key="k"
      :src="banner_g.imagem"
      class="tamanho"
    />
    {{log()}}
    <!--    :src="'https://creativeapi.schaffenlab.com/api/download?image='+banner.imagem"-->
  </v-carousel>
</template>

<script>
  import primeiro_banner_p from "@/assets/images/banners/MatriculasAbertas-800x350px.png";
  import segundo_banner_p from "@/assets/images/banners/Muito-alem-dos-muros-800x350.png";
  import primeiro_banner_m from "@/assets/images/banners/MatriculasAbertas-1100x350px.png";
  import segundo_banner_m from "@/assets/images/banners/Ensino-Personalizado-1100x350.png";
  import terceiro_banner_m from "@/assets/images/banners/Muito-alem-dos-muros-1100x350.png";
  import primeiro_banner_g from "@/assets/images/banners/MatriculasAbertas-1300x350px.png";
  import segundo_banner_g from "@/assets/images/banners/Ensino-Personalizado-1300x350.png";
  import terceiro_banner_g from "@/assets/images/banners/Muito-alem-dos-muros-1300x350.png";


export default {
  name: "Banners",
  data: () => ({
    tamanhoTela: window.innerWidth,
    banners_p: [
      { imagem: primeiro_banner_p },
      { imagem: segundo_banner_p },
    ],
    banners_m: [
      { imagem: primeiro_banner_m },
      { imagem: segundo_banner_m },
      { imagem: terceiro_banner_m },
    ],
    banners_g: [
      { imagem: primeiro_banner_g },
      { imagem: segundo_banner_g },
      { imagem: terceiro_banner_g },
    ],
  }),
  methods:{
    log(){
      console.log(this.tamanhoTela);
    }
  }
};
</script>

<style>
.tamanho {
  padding-top: 50px;
}
</style>
