<template>
  <div class="cor-fundo icon">
    <div class="ma-auto agendamento">
      <h2 class="cor-branca-texto titulo-propaganda tamanho-fonte">Agende sua visita</h2>
      <v-row class="ml-15 mr-15 mb-3">
        <v-col cols="12" sm="6" md="6">
          <v-text-field
              label="Nome do responsável"
              class="style-input .v-input__slot py-4"
              hide-details="auto"
              v-model="nome_responsavel"
              rounded
              dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-select
              label="Quantidade de alunos"
              class="style-input .v-input__slot py-4"
              hide-details="auto"
              :items="selectQuantidade"
              v-model="qtde_alunos"
              rounded
              dense
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="ml-15 mr-15 pb-3">
        <v-col cols="12" sm="6" md="6">
          <v-text-field
              label="E-mail"
              class="style-input .v-input__slot py-4 pa-detalhes"
              hide-details="auto"
              v-model="email"
              :rules="emailRules"
              rounded
              dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
              label="Celular"
              class="style-input .v-input__slot py-4"
              hide-details="auto"
              v-model="celular"
              rounded
              dense
          ></v-text-field>
        </v-col>
      </v-row>
      <div v-for="n in quantidade_campos" class="aluno-box">
        <v-row class="ml-15 mr-15 pb-3">
          <v-col cols="12" sm="6" md="6">
            <v-text-field
                :label="'Nome do aluno ' + n"
                class="style-input .v-input__slot py-4"
                hide-details="auto"
                v-model="aluno[n-1].nome_aluno"
                rounded
                dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-select
                label="Ano pretendido"
                class="style-input .v-input__slot py-4"
                hide-details="auto"
                :items="selectAno"
                v-model="aluno[n-1].ano_pretendido"
                rounded
                dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="ml-15 mr-15 pb-3">
          <v-col cols="12" sm="6" md="6">
            <v-select
                label="Nível pretendido"
                class="style-input .v-input__slot py-4"
                hide-details="auto"
                :items="selectNivel"
                v-model="aluno[n-1].nivel_pretendido"
                rounded
                dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-select
                label="Turno"
                class="style-input .v-input__slot py-4"
                hide-details="auto"
                :items="aluno[n-1].nivel_pretendido.includes('Fundamental')?selectTurnoFundamental:selectTurno"
                v-model="aluno[n-1].turno"
                rounded
                dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="ml-15 mr-15 pb-3 ">
          <v-col cols="12" sm="6" md="6">
            <p class="pl-9">O aluno precisa de acompanhamento pedagógico
  especializado?</p>
          </v-col>
          <v-col cols="12" sm="6" md="6" :key="componentKey">
              <v-checkbox
                v-model="aluno[n-1].acomp_esp"
                class="checkbox icon"
                :label= "aluno[n-1].acomp_esp==true?'Sim':'Não'"
                color="#ea5c2d"
                hide-details
                @change="componentKey++"
              ></v-checkbox>
          </v-col>
        </v-row>
      </div>
      <!---<v-row class="ml-15 mr-15 pb-10">
        <v-col cols="12" sm="12" md="12">
          <v-textarea
              label="Mensagem"
              class="style-input .v-input__slot"
              hide-details="auto"
          ></v-textarea>
        </v-col>
      </v-row>--->
      <v-row class="ml-15 mr-15 pb-10 pt-7">
        <DefaultButton v-if="send_ok==false" text="Quero agendar uma visita" @click.native="enviarEmail()"></DefaultButton>
        <v-alert
          v-if="send_ok==true"
          prominent
          class="ma-auto mt-3"
          style="width:100%"
          type="success"
        >
          <v-row align="center">
            <v-col class="grow">
              Email enviado com sucesso, entraremos em contato para agendar sua visita!
            </v-col>
          </v-row>
        </v-alert>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'FaleConosco',
  components: {
    DefaultButton: () => import("@/components/DefaultComponents/DefaultButton"),
  },
  data: () => ({
    quantidade_campos: 1,
    componentKey: 0,
    send_ok: false,
    error: '',
    email: '',
    celular: '',
    nome_responsavel: '',
    qtde_alunos: 1,
    emailRules: [ 
      v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Informe um e-mail válido'
    ],
    aluno: [
      {
        ano_pretendido: '',
        nivel_pretendido: '',
        turno: '',
        nome_aluno: '',
        acomp_esp: false,
      }
    ],
    selectQuantidade:[
      1,
      2,
      3,
      4,
      5,
    ],
    selectAno:[
      '2022',
      '2023',
      '2024',
      '2025',
      '2026',
      '2027',
    ],
    selectNivel:[
      'Infantil 1 - Educação Infantil',
      'Infantil 2 - Educação Infantil',
      'Infantil 3 - Educação Infantil',
      'Infantil 4 - Educação Infantil',
      'Infantil 5 - Educação Infantil',
      '1º ano - Ensino Fundamental',
      '2º ano - Ensino Fundamental',
      '3º ano - Ensino Fundamental',
      '4º ano - Ensino Fundamental',
      '5º ano - Ensino Fundamental',
    ],
    selectTurno:[
      'Integral',
      'Intermediário',
      'Meio Período',
    ],
    selectTurnoFundamental:[
      'Integral',
      'Intermediário',
    ],
  }),
  watch:{
    qtde_alunos(){
      if(this.qtde_alunos>this.quantidade_campos){
        for(var i=this.quantidade_campos ; i<this.qtde_alunos ; i++){
          console.log('muda')
          this.aluno[i]= {
            ano_pretendido: '',
            nivel_pretendido: '',
            turno: '',
            nome_aluno: '',
            acomp_esp: false,
          }
        }
      }
      this.quantidade_campos = this.qtde_alunos
    },
  },

  methods: {
    enviarEmail(){
      var alunos = []
      for(var i=0 ; i<this.qtde_alunos ; i++){
        var novo_aluno = this.aluno[i]
        alunos.push(novo_aluno)
      }
      const dados = {
          "nome_responsavel": this.nome_responsavel,
          "qtde_alunos": this.qtde_alunos,
          "email": this.email,
          "celular": this.celular,
          "alunos": alunos,
        }
          axios
            .post(process.env.VUE_APP_API_URL+"/api/send-mail-contato", dados)
            .then((response) => {
              this.send_ok = true
            })
            .catch((error) => {
              this.send_ok = false
              console.log(error);
            });
    }
  }
}
</script>

<style>
.cor-fundo {
  background-color: #9CCB47
}

.cor-branca-texto {
  color: white;
  text-align: center;
  padding-top: 3%;
  padding-bottom: 2%;
}

.style-input {
  background-color: white;
  border-radius: 13px;
}

.v-input__slot {
  left: 11px;
  width: 96% !important;
}

.aluno-box{
   background: #9CCB47;
   margin: 0px 0px;
}

.aluno-numero{
   margin: 0 0 0 75px;
}

.checkbox{
  justify-content: center;
  display: flex;
  margin-top: 8px !important;
}

.v-input--selection-controls{
  margin-top: 8px;
}
</style>

<style scoped>
.icon >>> .mdi:before{
  font-size: 30px !important;
}

.agendamento{
  max-width: 1000px;
}

.pa-detalhes >>> .v-text-field__details{
  margin: 0 0 0 35px !important;
}
</style>
