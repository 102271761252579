<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import Home from './views/Home';

export default {
  name: 'App',

  components: {
    Home,
  },
};
</script>

<style>

@font-face {
  font-family: "hamburadul-italic";
  src: url(~@/assets/fonts/HambuRadul-Italic.ttf);
}

@font-face {
  font-family: "hamburadul-regular";
  src: url(~@/assets/fonts/HambuRadul-Regular.ttf);
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: white;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

</style>
