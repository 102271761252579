<template>
  <div class="d-flex flex-column align-center">
    <div class="adiciona-button">
        <v-col v-show="!adicionar" cols="12" class="my-2 align-center tam" style="padding:0px">
          <v-btn v-on:click="adicionarDiferencial" color="#98C238" dark>Adicionar diferencial</v-btn>
        </v-col>
      <v-col cols="12" class="align-center" style="padding:0px">
        <h1 class="mb-3">Diferenciais</h1>
      </v-col>
    </div>
    <div v-if="adicionar" style="width:85%">
      <v-row class="mb-5">
        <v-col cols="12" sm="12" md="12">
          <v-text-field
              label="Título"
              v-model="defaultItem.titulo"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-textarea
              name="input-7-1"
              label="Adicione texto referente ao diferencial"
              v-model="defaultItem.texto"
              hint="Procure descrever com detalhes o diferencial que a escola possui"
          ></v-textarea>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <DefaultDocumentInput
              text="Anexar imagem para o diferencial"
              :required="true"
              type="diferencial"
              module="'gfsgfsd'"
              reqText="Selecione o arquivo"
              @update:returnFile="defaultItem.imagem = $event"
          />
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <DefaultButton :cor="1" :text="'Cancelar'" @click.native="adicionarDiferencial()"></DefaultButton>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <DefaultButton :text="(editar == true)?'Salvar Edição':'Adicionar'"
                         @click.native="salvarNovoDiferencial()"></DefaultButton>
        </v-col>
      </v-row>
    </div>
    <v-data-table
        :headers="headers"
        :items="diferenciais"
        :page.sync="page"
        :items-per-page="7"
        hide-default-footer
        v-show="!adicionar"
        class="elevation-1 estilo-tabela"
        @page-count="pageCount = $event">
      <template v-slot:top>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Tem certeza que quer excluir esse diferencial?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2 icon_acoes"
            @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            class="icon_acoes"
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <h4 class="mb-1 mt-1">Lista de diferenciais vazia</h4>
      </template>
    </v-data-table>
    <div v-show="!adicionar" class="text-center pt-2">
      <v-pagination
          class="pagination"
          v-model="page"
          :length="pageCount"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import axios from "../../services/axios";

export default {
  name: 'AddDiferenciais',
  components: {
    DefaultDocumentInput: () => import("@/components/DefaultComponents/DefaultDocumentInput"),
    DefaultButton: () => import("@/components/DefaultComponents/DefaultButton"),
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      adicionar: false,
      editar: false,
      page: 1,
      pageCount: 0,
      headers: [
        {
          text: 'Nome',
          align: 'start',
          sortable: false,
          value: 'titulo',
        },
        {text: 'Texto', value: 'texto', sortable: false,},
        {text: 'Imagem', value: 'imagem', sortable: false,},
        {text: 'Ações', value: 'actions', sortable: false},
      ],
      diferenciais: [],
      editedIndex: -1,
      editedItem: {
        titulo: '',
        imagem: '',
        texto: '',
        codigo: -1
      },
      defaultItem: {
        titulo: '',
        imagem: '',
        texto: '',
        codigo: -1
      },
    }
  },
  mounted() {
    this.inicializa()
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    inicializa() {
      axios
        .get("/api/diferenciais")
        .then((response) => {
          this.diferenciais = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    adicionarDiferencial() {
      this.adicionar = !this.adicionar;
      if (this.editar == true) this.editar = false
    },
    salvarNovoDiferencial() {
      const dados = {
        "title": this.defaultItem.titulo,
        "file_name": this.defaultItem.imagem[0].name,
        "image": this.defaultItem.imagem[0].base64,
        "explanation": this.defaultItem.texto
      }
      if (this.editar != true) {
        axios
          .post("/api/diferenciais", dados, {
            headers: {},
          })
          .then((response) => {
            this.inicializa();
          })
          .catch((error) => {
            console.log(error);
          });
        this.adicionar = !this.adicionar;
      } else {
        axios
          .put(
            "/api/diferenciais/" +
              this.defaultItem.codigo,
            dados,
            {
              headers: {},
            }
          )
          .then((response) => {
            this.inicializa();
          })
          .catch((error) => {
            console.log(error);
          });
        this.adicionar = !this.adicionar;
      }
      this.defaultItem = {
        titulo: '',
        imagem: '',
        texto: '',
        codigo: -1
      };
    },
    deleteItem(item) {
      this.editedIndex = this.diferenciais.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    editItem(item) {
      if (this.adicionar == false) this.adicionar = true
      this.defaultItem.titulo = item.titulo
      this.defaultItem.imagem = item.imagem
      this.defaultItem.texto = item.texto
      this.defaultItem.codigo = item.codigo
      this.editar = true
    },
    deleteItemConfirm() {
      axios
        .delete(
          "/api/diferenciais/" +
            this.diferenciais[this.editedIndex].codigo
        )
        .then((response) => {
        })
        .catch((error) => {
          console.log(error);
        });
      this.diferenciais.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
}
</script>

<style>

.estilo-tabela {
  width: 100% !important;
}

.pagination > ul > li > button > i {
  font-size: 45px !important;
}

h1{
  text-align: center;
}
</style>
