<template>
  <div class="d-flex flex-column align-center">
    <div class="adiciona-button">
        <v-col v-show="!adicionar" cols="12" class="my-2 align-center tam" style="padding:0px">
          <v-btn v-on:click="adicionarCardapio" color="#98C238" dark>Adicionar Cardápio</v-btn>
        </v-col>
        <v-col cols="12" class="align-center" style="padding:0px">
          <h1 class="mb-3">Cardápio</h1>
        </v-col>
    </div>
    <div v-if="adicionar" style="width:85%">
      <v-row>
      <v-col cols="12" sm="6" md="6">
        <DefaultDatePicker
          :label="'Data de Iní­cio'"
          :data="(editedItem.start_date == '')? '': formataData(editedItem.start_date)"
          @update:returnDate="editedItem.start_date = $event"/>
        </v-col>
        <v-col cols="12" sm="6" md="6">
        <DefaultDatePicker
          :label="'Data do Fim'"
          :data="(editedItem.end_date == '')? '': formataData(editedItem.end_date)"
          @update:returnDate="editedItem.end_date = $event"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <DefaultDocumentInput
              text="Anexar cardápio"
              :required="true"
              type="cardapios"
              module="editedItem.arquivo"
              reqText="Selecione o arquivo"
              @update:returnFile="editedItem.arquivo = $event"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <DefaultButton :cor="1" :text="'Cancelar'" @click.native="adicionarCardapio()"></DefaultButton>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <DefaultButton :text="(editar == true)?'Salvar Edição':'Adicionar'"
                         :disable="(this.editedItem.start_date != '' && this.editedItem.end_date != '' && this.editedItem.arquivo != null)?false:true"
                         @click.native="salvarNovoCardapio()"></DefaultButton>
        </v-col>
      </v-row>
    </div>
    <v-data-table
        :headers="headers"
        :items="cardapios"
        :page.sync="page"
        :items-per-page="10"
        v-show="!adicionar"
        hide-default-footer
        class="elevation-1 estilo-tabela"
        @page-count="pageCount = $event"
        :loading="loading"
        loading-text="Carregando...">
      <template v-slot:item.start_date="{item}">
        <span>
          {{formataData(item.start_date)}}
        </span>
      </template>
      <template v-slot:item.end_date="{item}">
        <span>
          {{formataData(item.end_date)}}
        </span>
      </template>
      <template v-slot:item.arquivo="{item}">
          <img @click="openIMG(item)" :src="'data:image/png;base64,' + item.image" width="294" height="416"/>
      </template>
      <template v-slot:top>
        <v-dialog v-model="dialogDelete" max-width="550px">
          <v-card>
            <v-card-title class="headline">Tem certeza que quer excluir esse cardapio?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.actions="{ item }">
        {{terminaLoading()}}
        <v-icon
            small
            class="mr-2 icon_acoes"
            @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            class="icon_acoes"
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <h4 class="mb-1 mt-1">Lista de cardapios vazia</h4>
      </template>
    </v-data-table>
    <div v-show="!adicionar" class="text-center pt-2">
      <v-pagination
          class="pagination"
          v-model="page"
          :length="pageCount"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import axios from "../../services/axios";
import moment from "moment";

export default {
  name: 'AddCardapios',
  components: {
    DefaultDocumentInput: () => import("@/components/DefaultComponents/DefaultDocumentInput"),
    DefaultDatePicker: () => import("@/components/DefaultComponents/DefaultDatePicker"),
    DefaultButton: () => import("@/components/DefaultComponents/DefaultButton"),
  },
  data() {
    return {
      loading: false,
      dialog: false,
      dialogDelete: false,
      adicionar: false,
      editar: false,
      page: 1,
      pageCount: 0,
      StringImg1: 'data:image/png;base64,',
      headers: [
        {text: 'Data de Início', value: 'start_date', sortable: true,},
        {text: 'Data final', value: 'end_date', sortable: false,},
        {text: 'Cardápio', value: 'arquivo', sortable: false,},
        {text: 'Ações', value: 'actions', sortable: false},
      ],
      cardapios: [],
      editedIndex: -1,
      editedItem: {
        codigo: '',
        arquivo: '',
        start_date: '',
        end_date: '',
      },
      defaultItem: {
        codigo: '',
        arquivo: '',
        start_date: '',
        end_date: '',
      },
    }
  },
  mounted() {
    this.inicializa()
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    inicializa() {
      this.loading = true,
      axios
        .get("/api/cardapios")
        .then((response) => {
          this.cardapios = response.data.data;
          if(this.cardapios.length == 0)this.terminaLoading();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    adicionarCardapio() {
      this.adicionar = !this.adicionar;
      if (this.editar == true) this.editar = false
      this.editedItem= {
        codigo: '',
        arquivo: '',
        start_date: '',
        end_date: '',
      }
    },
    salvarNovoCardapio() {
      if (this.editedItem.start_date != '' && this.editedItem.end_date != '' && this.editedItem.arquivo != null) {

        const dados = {
          "image": this.editedItem.arquivo[0].base64,
          "start_date": moment(this.editedItem.start_date).format("YYYY-MM-DD"),
          "end_date": moment(this.editedItem.end_date).format("YYYY-MM-DD"),
          "id": this.editedItem.codigo,
        }
        console.log("this.editedItem", this.editedItem)
        console.log(dados)
        if (this.editar != true) {
          axios
            .post("/api/cardapios", 
              dados, {
              headers: {},
            })
            .then((response) => {
              console.log(response);
              this.inicializa();
            })
            .catch((error) => {
              console.log(error);
            });
          this.adicionar = !this.adicionar;
        } else {
          axios
            .put(
              "/api/cardapios/" +
                this.editedItem.codigo,
              dados,
              {
                headers: {},
              }
            )
            .then((response) => {
              console.log(response.data[0]);
              this.inicializa();
            })
            .catch((error) => {
              console.log(error);
            });
          this.adicionar = !this.adicionar;
        }
        this.editedItem = this.defaultItem;
      }
    },
    deleteItem(item) {
      this.editedIndex = this.cardapios.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    editItem(item) {
      if (this.adicionar == false) this.adicionar = true
      this.editedItem.codigo = item.id
      this.editedItem.arquivo = item.arquivo
      this.editedItem.end_date = item.end_date
      this.editedItem.start_date = item.start_date
      this.editar = true
    },
    deleteItemConfirm() {
      console.log(this.cardapios[this.editedIndex].codigo)
      axios
        .delete(
          "/api/cardapios/" +
            this.cardapios[this.editedIndex].id
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      this.cardapios.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem)
        this.editedIndex = -1
      })
    },
    formataData(data){
      return moment(String(data)).format('DD/MM/YYYY')
    },
    terminaLoading(){
      this.loading = false
    },
    openIMG(item){
      var win = window.open();
      win.document.write("<img style=\"margin:auto;width:100%;\" src='data:image/png;base64,"+item.image+"'/>");
    }
  },
}
</script>

<style>
.estilo-tabela {
  width: 100% !important;
}

.pagination > ul > li > button > i {
  font-size: 45px !important;
}

.pdf{
  height: 430px;
}
</style>
