<template>
  <div class="d-flex flex-column align-center">
   <div class="adiciona-button">
        <v-col v-show="!adicionar" cols="12" class="my-2 align-center tam" style="padding:0px">
          <v-btn v-on:click="adicionarComunicado" color="#98C238" dark>Adicionar Comunicado</v-btn>
        </v-col>
        <v-col cols="12" class="align-center" style="padding:0px">
          <h1 class="mb-3">Comunicados</h1>
        </v-col>
    </div>
    <div v-if="adicionar" style="width:85%">
      <v-row style="width:104%">
        <v-col cols="12" sm="12" md="12">
          <v-text-field
              label="Título"
              v-model="editedItem.titulo"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row style="width:102%" class="pa-bottom-3">
        <v-col cols="12" sm="6" md="6">
          <DefaultDatePicker
            :label="'Data de Início'"
            :data="(editedItem.data_inicio == '')? '': formataData(editedItem.data_inicio)"
            @update:returnDate="editedItem.data_inicio = $event"/>
          </v-col>
          <v-col cols="12" sm="6" md="6">
          <DefaultDatePicker
            :label="'Data do Fim'"
            :data="(editedItem.data_fim == '')? '': formataData(editedItem.data_fim)"
            @update:returnDate="editedItem.data_fim = $event"/>
          </v-col>
        <v-col cols="12" sm="6" md="6" class="no-pa-top">
          <v-select
              :items="tipoComunicado"
              label="Tipo do comunicado"
              item-text="nome"
              item-value="id"
              v-model="editedItem.tipo_evento_id"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="6" class="no-pa-top">
          <v-select
              :items="salas"
              label="Sala"
              item-text="nome"
              item-value="classroom_id"
              v-model="editedItem.id_sala"
          ></v-select>
        </v-col>

        <v-row>
          <v-col cols="12" sm="4" md="4">
            <div class="pa-switch">
              <div class="labelSwitch">Enviar para todos os alunos:</div>
            </div>
          </v-col>
          <v-col cols="12" sm="1" md="1">
            <div class="switch">
              <v-switch
                  v-model="selectTodos"
                  inset
                  color="red"
                  :disabled="selectTodos == true"
                ></v-switch>
            </div>
          </v-col>
          <v-col cols="12" sm="1" md="1"/>
          <v-col cols="12" sm="4" md="4">
            <div class="pa-switch">
              <div class="labelSwitch">Enviar para alunos selecionados:</div>
            </div>
          </v-col>
          <v-col cols="12" sm="1" md="1">
            <v-switch
              v-model="selectAlunos"
              inset
              color="red"
              :disabled="selectAlunos == true"
            ></v-switch>
          </v-col>
        </v-row>
      </v-row>

      <v-row>
        <div 
        class="pa-3 checkboxAlunos" 
        :v-if="toSelectAlunos != []" 
        v-for="(item, index) in toSelectAlunos"
        :key="item.codigo">
          <v-checkbox
          v-model="checkboxAluno"
          class="pa-2 checkboxAlunosBlock"
          :label="item.name"
          :value="item.codigo"/>
        </div>
      </v-row>

      <v-row class="pa-top-3">
        <v-col cols="12" sm="12" md="12" >
          <v-textarea
              name="input-7-1"
              label="Adicione texto referente ao comunicado"
              v-model="editedItem.descricao"
              hint="Insira uma mensagem detalhada sobre o comunciado"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" class="pa-pdf">
          <DefaultDocumentInput
              text="Anexar PDF"
              :required="false"
              type="cardapios"
              module="editedItem.arquivo"
              reqText="Selecione o arquivo"
              @update:returnFile="editedItem.arquivo = $event"
          />
        </v-col>
      </v-row>
      <v-row class="mb-5">
        <v-col cols="12" sm="6" md="6">
          <DefaultButton :cor="1" :text="'Cancelar'" @click.native="adicionarComunicado()"></DefaultButton>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <DefaultButton :text="(editar == true)?'Salvar Edição':'Adicionar'"
                         :disable="(this.editedItem.titulo != '' && this.editedItem.descricao != '' && this.editedItem.data_inicio != null && this.editedItem.tipo_evento_id != '' && this.editedItem.id_sala != null)?false:true"
                         @click.native="salvarNovoComunicado()"></DefaultButton>
        </v-col>
      </v-row>
    </div>
    <v-card v-if="!adicionar" style="width:85%">
      <v-card-title style="">
        <v-col cols="12" sm="3" md="3">
          Filtrar por sala:
          <v-spacer></v-spacer>
        </v-col>
        <v-col cols="12" sm="7" md="7">
            <v-select
                :items="salas"
                label="Sala"
                item-text="nome"
                item-value="classroom_id"
                v-model="search"
            ></v-select>
          </v-col>
          <v-col v-show="!adicionar" cols="12" sm="2" md="2">
          <v-btn v-on:click="search = null" color="#98C238" dark>Ver Todos</v-btn>
        </v-col>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="comunicados"
          :page.sync="page"
          :items-per-page="45"
          hide-default-footer
          v-show="!adicionar"
          class="elevation-1 estilo-tabela"
          :page-count="pageCount"
          :loading="loading"
          loading-text="Carregando...">

        <template v-slot:top>
          <v-dialog v-model="dialogDelete" max-width="580px">
            <v-card>
              <v-card-title class="headline">Tem certeza que quer excluir esse comunicado?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>

         <template v-slot:item="{ item }">
            <tr>
              <td>{{item.titulo}}</td>
              <td>{{item.descricao}}</td>
              <td>{{item.nome_evento}}</td>
              <td>{{item.sala}}</td>
              <td>{{formataData(item.data_inicio)}}</td>
              <td><span v-show="item.data_fim != null">{{formataData(item.data_fim)}}</span></td>
              <td>
                <v-icon
                small
                class="mr-2 icon_acoes"
                @click="editItem(item)">
                mdi-pencil
                </v-icon>
                <v-icon
                small
                class="mr-2 icon_acoes"
                @click="deleteItem(item)">
                mdi-delete
                </v-icon>
                <v-icon
                  v-if="item.imagem != null && item.imagem != ''"
                  small
                  class="mr-2 icon_acoes"
                  @click="openIMG(item)">
                  mdi-magnify
                  </v-icon>
              </td>
            </tr>
          </template>

        <template v-slot:no-data>
          <h4 class="mb-1 mt-1">Lista de comunicados vazia</h4>
        </template>
      </v-data-table>
    </v-card>
    <div v-show="!adicionar" class="text-center pt-2">
      <v-pagination
          class="pagination"
          v-model="page"
          :length="pageCount"
      ></v-pagination>
    </div>
    <DefaultDialog
        :dialog="dialog"
        :title="(editar != true)?'Adicionado com sucesso':'Editado com sucesso'"
        :text="(editar != true)?'Seu comunicado foi adicionado com sucesso.':'Seu comunicado foi editado com sucesso.'"
        @update:closeDialog="dialog = $event"
    />
    <DefaultDialog
        :dialog="dialogErro"
        :title="'Ocorreu um erro'"
        :text="erro + 'Não foi possível cadastrar/editar esse comunicado, tente novamente ou abra um chamado na Schaffen.'"
        @update:closeDialog="dialogErro = $event"
    />
  </div>
</template>

<script>
import axios from "../../services/axios";
import moment from "moment";

export default {
  name: 'AdmComunicados',
  components: {
    DefaultDocumentInput: () => import("@/components/DefaultComponents/DefaultDocumentInput"),
    DefaultButton: () => import("@/components/DefaultComponents/DefaultButton"),
    DefaultDatePicker: () => import("@/components/DefaultComponents/DefaultDatePicker"),
    DefaultDialog: () => import("@/components/DefaultComponents/DefaultDialog"),
  },
  data() {
    return {
      selectAlunos: false,
      selectTodos: true,
      loading: false,
      dialog: false,
      dialogDelete: false,
      dialogErro: false,
      adicionar: false,
      editar: false,
      imagem: '',
      search: null,
      noDataCont: 0,
      page: 1,
      pageCount: 0,
      headers: [
        {text: 'Titulo',  align: 'start', sortable: false, value: 'titulo',},
        {text: 'Descrição', value: 'descricao', sortable: false,},
        {text: 'Tipo', value: 'nome_evento', sortable: false,},
        {text: 'Sala', value: 'sala', sortable: false,},
        {text: 'Data de Inicio', sortable: true, value: 'data_inicio',},
        {text: 'Data Final', sortable: true, value: 'data_fim',},
        {text: 'Ações', value: 'actions', sortable: false},
      ],
      comunicados: [],
      tipoComunicado: [],
      salas: [],
      toSelectAlunos: [],
      checkboxAluno: [],
      erro: '',
      editedIndex: -1,
      editedItem: {
        codigo: '',
        titulo: '',
        descricao: '',
        tipo_evento_id: '',
        data_inicio: '',
        data_fim: '',
        nome_evento: '',
        id_sala: null,
        alunos: [],
        arquivo: '',
        imagem64: '',
      },
      defaultItem: {
        codigo: '',
        titulo: '',
        descricao: '',
        tipo_evento_id: '',
        data_inicio: '',
        data_fim: '',
        nome_evento: '',
        id_sala: null,
        alunos: [],
        arquivo: '',
        imagem64: '',
      },
    }
  },
  mounted() {
    this.inicializa()
  },
  watch:{
    checkboxAluno(){
      //console.log('Alunos', this.checkboxAluno)
    },
    "editedItem.id_sala"(){
      if(this.selectAlunos == true && this.editedItem.id_sala != null){
        axios
          .get("/api/salas/" + this.editedItem.id_sala +"?with[]=alunos")
          .then((response) => {
            this.toSelectAlunos = response.data.data.alunos;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    selectAlunos(){
      if(this.selectAlunos == true){
        if(this.selectTodos == true){
          this.selectTodos = false
        }
        if(this.editedItem.id_sala == null || this.editedItem.id_sala == ''){
          return;
        }else{
          axios
          .get("/api/salas/" + this.editedItem.id_sala +"?with[]=alunos")
          .then((response) => {
            this.toSelectAlunos = response.data.data.alunos;
          })
          .catch((error) => {
            console.log(error);
          });
        }
      }
    },
    selectTodos(){
      if(this.selectTodos == true){
        if(this.selectAlunos == true){
          this.selectAlunos = false
        }
        this.toSelectAlunos = [];
      }
    },
    search() {
      this.page = 1
      this.getComunicados(this.page, this.search);
    },
    page(val) {
      if (this.page != null) {
        this.getComunicados(val, this.search);
      }
    }
  },
  methods: {
    inicializa() {
      this.loading = true,

      this.getComunicados(this.page);

      axios
        .get("/api/tipo_comunicados")
        .then((response) => {
          this.tipoComunicado = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get("/api/salas")
        .then((response) => {
          this.salas = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getComunicados(page, class_id) {
      this.pageCount = 1;
      this.loading = true;
      var url = ''
      if (class_id == null || class_id == undefined) {
        url = "/api/comunicados?with[]=alunos&page=" + page
      } else {
        url = "/api/comunicados?with[]=alunos&classroom_id=" + class_id + "&page=" + page
      }
      axios
        .get(url)
        .then((response) => {
          this.comunicados = [];
          this.comunicados = response.data.data;
          this.pageCount = response.data.meta.last_page;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    organizaCheckbox(index){
      if(index%5 == 0 && index != 0){
        //console.log('index', index)
        return true
      }else{
        return false
      }
    },
    formataData(data) {
      return moment(String(data)).format('DD/MM/YYYY')
    },
    terminaLoading(){
      this.loading = false
    },
    openIMG(item){
      var win = window.open("data:application/pdf;base64,"+item.imagem);
      //win.document.write("<img style=\"margin:auto;width:100%;\" src='data:image/png;base64,"+item.imagem+"'/>");
      win.document.write("<iframe style=\"margin:auto;width:100%;height:99%\" src='data:application/pdf;base64,"+item.imagem+"'></iframe>");
      
    },
    adicionarComunicado() {
      this.adicionar = !this.adicionar;
      if (this.adicionar == false) {
        this.editedItem = {
          codigo: '',
          titulo: '',
          descricao: '',
          tipo_evento_id: '',
          data_inicio: '',
          data_fim: '',
          nome_evento: '',
          alunos: [],
          id_sala: null,
          imagem64: '',
        }
        this.selectTodos = true;
      }
      if (this.editar == true) {
        this.editar = false
        this.editedItem = {
          codigo: '',
          titulo: '',
          descricao: '',
          tipo_evento_id: '',
          data_inicio: '',
          data_fim: '',
          nome_evento: '',
          alunos: [],
          id_sala: null,
          imagem64: '',
        }
        this.selectTodos = true;

      }
    },
    salvarNovoComunicado() {
      if (this.editedItem.titulo != '' && this.editedItem.descricao != '' && this.editedItem.data_inicio != null && this.editedItem.tipo_evento_id != '' && this.editedItem.id_sala != null) {
        var dados = {};
        if(this.editedItem.arquivo == '' && this.editedItem.imagem64 == ''){
          dados = {
          "title": this.editedItem.titulo,
          "description": this.editedItem.descricao,
          "event_type_id": this.editedItem.tipo_evento_id,
          "start_date": moment(this.editedItem.data_inicio).format("YYYY-MM-DD"),
          "end_date": this.editedItem.data_fim,
          "classroom_id": this.editedItem.id_sala,
          "id": this.editedItem.codigo,
          "students": this.checkboxAluno,
	        "imagem" : null,
        };
        }else if(this.editedItem.arquivo != ''){
          dados = {
          "title": this.editedItem.titulo,
          "description": this.editedItem.descricao,
          "event_type_id": this.editedItem.tipo_evento_id,
          "start_date": moment(this.editedItem.data_inicio).format("YYYY-MM-DD"),
          "end_date": this.editedItem.data_fim,
          "classroom_id": this.editedItem.id_sala,
          "id": this.editedItem.codigo,
          "students": this.checkboxAluno,
          "imagem" : this.editedItem.arquivo[0].base64,
        };
        }else{
          dados = {
          "title": this.editedItem.titulo,
          "description": this.editedItem.descricao,
          "event_type_id": this.editedItem.tipo_evento_id,
          "start_date": moment(this.editedItem.data_inicio).format("YYYY-MM-DD"),
          "end_date": this.editedItem.data_fim,
          "classroom_id": this.editedItem.id_sala,
          "id": this.editedItem.codigo,
          "students": this.checkboxAluno,
	        "imagem" : this.editedItem.imagem64,
        };
        }
        if (this.editar != true) {
          axios
            .post("/api/comunicados", dados,
              {
                headers: {},
              }
            )
            .then(response => {
              if (response === undefined) {
                this.erro = "A descrição ultrapassa a quantidade permitida de caracteres, 540. ";
                this.dialogErro = true;
              } else{
              this.inicializa();
              this.adicionar = !this.adicionar;
              this.erro = false;
              this.editedItem = {
                codigo: '',
                titulo: '',
                descricao: '',
                tipo_evento_id: '',
                data_inicio: '',
                data_fim: '',
                nome_evento: '',
                alunos: [],
                id_sala: null,
                imagem64: '',
              }
              }
            })
            .catch(error => {
              console.log(error);
              this.dialogErro = true;
            });
        } else {
          axios
            .put(
              "/api/comunicados/" +
                this.editedItem.codigo,
              dados,
              {
                headers: {},
              }
            )
            .then(response => {
              if (response === undefined) {
                this.erro = "A descrição ultrapassa a quantidade permitida de caracteres, 540. ";
                this.dialogErro = true;
              } else{
              this.inicializa();
              this.adicionar = !this.adicionar;
              this.erro = false;
              this.editedItem = {
                codigo: '',
                titulo: '',
                descricao: '',
                tipo_evento_id: '',
                data_inicio: '',
                data_fim: '',
                nome_evento: '',
                alunos: [],
                id_sala: null,
              }
              }
            })
            .catch(error => {
              console.log(error);
              this.dialogErro = true;
            });
        }
      }
    },
    deleteItem(item) {
      this.editedIndex = this.comunicados.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    editItem(item) {
      if (this.adicionar == false) this.adicionar = true
      this.editedItem.codigo = item.codigo
      this.editedItem.titulo = item.titulo
      this.editedItem.descricao = item.descricao
      this.editedItem.tipo_evento_id = item.tipo_evento_id
      this.editedItem.data_inicio = item.data_inicio
      this.editedItem.data_fim = item.data_fim
      this.editedItem.nome_evento = item.nome_evento
      this.editedItem.id_sala = item.id_sala
      this.editedItem.imagem64 = item.imagem
      if(item.alunos.length != 0){
        for(var i = 0; i<item.alunos.length ; i++){
          this.editedItem.alunos[i] = item.alunos[i].codigo
        }
        this.selectAlunos = true
        this.checkboxAluno = this.editedItem.alunos
      }

      this.editar = true
    },
    deleteItemConfirm() {
      axios
        .delete(
          "/api/comunicados/" +
            this.comunicados[this.editedIndex].codigo
        )
        .then(() => {
        })
        .catch((error) => {
          console.log(error);
        });
      this.comunicados.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
}
</script>

<style scoped>
.estilo-tabela {
  width: 100% !important;
}

.pagination > ul > li > button > i {
  font-size: 45px !important;
}

.labelSwitch{
  text-align: left;
  padding-top: 20px;
  width: 240px;
  display: flex;
}

.switch{
  text-align: center;
  padding-left: 0px;
}

.pa-switch{
  padding: 12px 22px;
}

.checkboxAlunos >>> .mdi:before{
  font-size: 40px !important;
}

.checkboxAlunosBlock{
  width: 100px;
  text-align: center;
}

.card{
  border: 1px solid rgba(0, 0, 0, 0.37);
  border-radius: 5px;
  margin: 0 12px;
  padding: 12px 12px; 
}

.card:hover{
  border: 1px solid rgba(0, 0, 0, 0.836);
}

.card1{
  margin-left: 23px;
}

.card2{
  margin-right: 23px;
}

.no-pa-top{
  padding-top: 0px;
}

.pa-top-3{
  padding-top: 12px;
}

.pa-bottom-3{
  padding-bottom: 12px;
}

.pa-pdf{
  padding: 12px 25px;
}

</style>
