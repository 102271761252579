<template class="cor-fundo-atendimento">
  <div>
    <div class="d-flex align-center mt-15 " style="justify-content: center">
      <div style="visibility: hidden; margin-left: auto;"></div>
      <h1 style="margin-left: auto">Atendimentos</h1>
      <v-btn
        style="margin-left: auto; margin-right: 5%;"
        v-on:click="abrirNovoAtendimento()"
        color="green"
        dark
      >
        Adicionar atendimento
      </v-btn>
    </div>
    <div v-if="adicionar" class="mt-10">
      <div class="d-flex justify-space-around flex-wrap margin-dados">
        <card-selection
          v-for="(atendimento, index) in atendimentos"
          :key="index"
          :title="atendimento.nome"
          :selected="atendimento.selected"
          :icon="atendimento.icone"
          @click.native="changeSelected(index)"
        ></card-selection>
        <v-row class="mt-10">
          <v-col cols="12" sm="6" md="6">
            <DefaultButton
              :cor="1"
              :text="'Cancelar'"
              @click.native="abrirNovoAtendimento()"
            ></DefaultButton>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <DefaultButton
              :text="'Agendar atendimento'"
              :disable="agendarAtendimentoId != null ? false : true"
              @click.native="salvarNovoAtendimento()"
            ></DefaultButton>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="mt-16">
      <ListCardItem
        v-for="(agendamento, index) in agendamentos"
        :key="index"
        :title="agendamento.nome_atendimento"
        :date="agendamento.agendado_em"
        :icon="agendamento.icone"
        :serviceStatusName="agendamento.status_atendimento"
        :codStatus="agendamento.cod_status_atendimento"
      ></ListCardItem>
    </div>
    <DefaultDialog
      :dialog="dialog"
      :title="'Adicionado com sucesso'"
      :text="'Seu atendimento foi adicionado com sucesso.'"
      @update:closeDialog="dialog = $event"
    />
  </div>
</template>

<script>
import axios from "../../services/axios";
import moment from "moment";

export default {
  name: "Atendimentos",
  components: {
    ListCardItem: () =>
      import("@/components/DefaultComponents/CardAtendimentos.vue"),
    CardSelection: () => import("@/components/CardSelection.vue"),
    DefaultButton: () => import("@/components/DefaultComponents/DefaultButton"),
    DefaultDialog: () => import("@/components/DefaultComponents/DefaultDialog"),
  },
  data: () => ({
    dialog: false,
    atendimentos: [],
    agendamentos: [],
    adicionar: false,
    agendarAtendimentoId: null,
    items: [
      {
        titulo: "Odontopediatria",
        icon: "mdi-tooth-outline",
        selected: false,
      },
      {
        titulo: "Nutricionusta",
        icon: "mdi-silverware-clean",
        selected: false,
      },
      {
        titulo: "Psicólogo",
        icon: "mdi-brain",
        selected: false,
      },
      {
        titulo: "Coordenação/ Direção Pedagógica",
        icon: "mdi-book-open-page-variant-outline",
        selected: false,
      },
      {
        titulo: "Pediatra",
        icon: "mdi-stethoscope",
        selected: false,
      },
      {
        titulo: "Professor da turma",
        icon: "mdi-school-outline",
        selected: false,
      },
    ],
  }),
  beforeMount() {
    this.inicializa();
    this.getAgendamentos();
  },
  methods: {
    inicializa() {
      axios
        .get("/api/atendimentos")
        .then((response) => {
          this.atendimentos = response.data.data;
          for (var i = 0; i < this.atendimentos.length; ++i) {
            this.atendimentos[i].selected = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAgendamentos() {
      axios
        .get("/api/agendamentos", {
          with: ["service", "schedule_status"],
        })
        .then((response) => {
          this.agendamentos = response.data.data;
          console.log("agendamentos: ", this.agendamentos);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeSelected(index) {
      for (var i = 0; i < this.atendimentos.length; ++i) {
        if (index == i) {
          this.atendimentos[i].selected = !this.atendimentos[index].selected;
          if (this.atendimentos[i].selected) this.agendarAtendimentoId = index;
          else this.agendarAtendimentoId = null;
        } else {
          this.atendimentos[i].selected = false;
        }
        this.$forceUpdate();
      }
    },
    abrirNovoAtendimento() {
      this.adicionar = !this.adicionar;
      if (this.adicionar == false) {
        this.agendarAtendimentoId = null;
        for (var i = 0; i < this.atendimentos.length; ++i) {
          this.atendimentos[i].selected = false;
        }
      }
    },
    salvarNovoAtendimento() {
      if (this.agendarAtendimentoId != null) {
        this.dialog = true;
        const dados = {
          requested_at: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          scheduled_at: null,
          schedule_status_id: 1,
          services_id: this.agendarAtendimentoId,
        };
        console.log("Olhe os dados:", dados);
        axios
          .post("/api/agendamentos", dados, {
            headers: {},
          })
          .then((response) => {
            console.log("response::", response);
            this.getAgendamentos();
          })
          .catch((error) => {
            console.log("error::", error);
          });
        this.adicionar = !this.adicionar;
        this.agendarAtendimentoId = null;
        for (var i = 0; i < this.atendimentos.length; ++i) {
          this.atendimentos[i].selected = false;
        }
      }
    },
  },
};
</script>

<style>
.cor-fundo-atendimento {
  background-color: #e7eef7;
}

.margin-dados {
  margin-left: 15%;
  margin-right: 15%;
}
</style>
