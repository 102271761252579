<template>
  <div class="mx-auto" elevation="8" max-width="800">
    <h1 class="titulo mt-15">Diferenciais</h1>
    <v-slide-group v-model="model" class="pa-4" show-arrows>
      <v-slide-item
        v-for="(diferencial, i) in diferenciais"
        :key="i"
        v-slot="{ active, toggle }"
        :class="diferenciais.length > 3 ? 'mr-5' : ''"
      >
        <v-card class="mx-auto my-12 card-item" max-width="300" @click="toggle">
          <img class="card-img" height="300" :src="diferencial.imagem" />
          <!--          :src="'https://creativeapi.schaffenlab.com/api/download?image='+diferencial.imagem"-->
        </v-card>
      </v-slide-item>
    </v-slide-group>

    <v-expand-transition>
      <v-sheet v-if="model != null" class=" mb-7" tile>
        <v-row
          class="fill-height"
          align="center"
          justify="center"
          style="display: flex; flex-direction: column; background-color: #fed112"
        >
          <h1 class="mt-1 mb-1 titulo-card mt-4">
            {{ diferenciais[model].titulo }}
          </h1>
          <p
            class="texto-justificado title ml-15 mr-15 mb-10 fundo-escrita-card"
          >
            {{ diferenciais[model].texto }}
          </p>
        </v-row>
      </v-sheet>
    </v-expand-transition>
  </div>
</template>

<script>
import imagem1 from "@/assets/images/diferenciais/aula_chikung.jpeg";
import imagem2 from "@/assets/images/diferenciais/escola_creative_learning_alimentacao.jpeg";
import imagem3 from "@/assets/images/diferenciais/escola_creative_learning_cookingclass.jpeg";
import imagem4 from "@/assets/images/diferenciais/escola_creative_learning_ensinobilingue.jpeg";
import imagem5 from "@/assets/images/diferenciais/escola_creative_learning_laboratorio.jpeg";
import imagem6 from "@/assets/images/diferenciais/escola_creative_learning_matriculas.jpeg";
import imagem7 from "@/assets/images/diferenciais/escola_creative_learning_odontopediatria.jpeg";
import imagem8 from "@/assets/images/diferenciais/escola_creative_learning_oficinageografia.jpeg";
import imagem9 from "@/assets/images/diferenciais/escola_creative_learning_pediatria.jpeg";

export default {
  name: "Diferenciais",
  // props: ['diferenciais'],
  data: () => ({
    diferenciais: [
      {
        titulo: "Aulas de relaxamento e Chi Kung",
        texto:
          "O Chi Kung é uma prática que promove o relaxamento corporal, aliviando, assim, sintomas de estresse e ansiedade. Sua combinação de movimentos e exercícios respiratórios favorecem um verdadeiro estado de paz, melhorando, inclusive, o sono. Os movimentos do Qi Gong promovem flexibilidade corporal, fortalecimento ósseo e muscular.",
        imagem: imagem1,
      },
      {
        titulo: "Alimentação balanceada",
        texto:
          "Além de melhorar a saúde e o desenvolvimento dos pequenos, uma alimentação saudável, rica em frutas, legumes e verduras, melhora o desempenho das crianças na escola, já que fornece os nutrientes necessários para o desenvolvimento cerebral, como vitaminas, proteínas e sais minerais. As dietas muito ricas em açúcares, por outro lado, deixam as crianças excessivamente energéticas, e quando o nível de açúcar no sangue diminui, tendem a ficar prostradas e fadigadas. Além disso, ter uma alimentação rica e variada permite à criança desenvolver um paladar mais sofisticado, pois ela aprende a apreciar a diversidade de sabores, cores, formas e texturas dos alimentos desde cedo.",
        imagem: imagem2,
      },
      {
        titulo: "Cooking Class",
        texto:
          "Traduzida do inglês, a expressão Cooking Class significa aula de culinária. No exercício lúdico desenvolvido pela escola, as crianças entram em contato com o universo gastronômico em outro idioma, cozinhando pratos que abrem o apetite de qualquer pequenino ou pequenina.",
        imagem: imagem3,
      },
      {
        titulo: "Ensino Bilingue",
        texto:
          "Nossos alunos não aprendem somente inglês, mas conquistam sua autonomia, desenvolvem novas habilidades em um ambiente seguro e com imersão na L.I. O resultado é efetivo com 03 certificações de CAMBRIDGE ao final do Ensino Fundamental 1.",
        imagem: imagem4,
      },
      {
        titulo: "Aulas de laboratório",
        texto:
          "Brincar e experimentar são sempre boas oportunidades também para aprender! As aulas envolvendo nosso laboratório promovem educação através da interação do estudante com o meio, através de desafios que agucem a curiosidade e cheguem à aprendizagem.",
        imagem: imagem5,
      },
      {
        titulo: "Odontopediatria",
        texto:
          "A odontopediatria garante que os primeiros dentes do seu filho permaneçam saudáveis e livres de cárie e outras doenças dentárias. A boa higiene bucal começa tão logo quanto a infância e a Cretive se preocupa com o bem-estar do seu filho.",
        imagem: imagem7,
      },
      {
        titulo: "Oficina de Geografia e História",
        texto:
          "A apropriação do Ensino de História e de Geografia de forma lúdica permite ao aluno, portanto cidadão, o desenvolvimento social, cultural, crítico, científico, tornando esses sujeitos de hoje ainda crianças futuros homens preparados para enfrentar os meio socioculturais prontos a assumir suas ambições e responsabilidades como adultos.",
        imagem: imagem8,
      },
      {
        titulo: "Pediatria Escolar",
        texto:
          "O acompanhamento do desenvolvimento e do crescimento é fundamental para a criança, pois permite evidenciar precocemente transtornos que afetam a saúde e, em especial, sua nutrição, sua capacidade mental e social. Mais do que um médico, o pediatra torna-se um parceiro da escola no desenvolvimento da vida da criança.",
        imagem: imagem9,
      },
    ],
    model: null,
  }),
};
</script>

<style>
.texto-justificado {
  text-align: justify;
}

.titulo {
  font-family: "hamburadul-regular";
  display: flex;
  justify-content: center;
  font-size: 40px;
}

.titulo-card {
  text-align: center;
  color: #fd7405;
  font-family: "hamburadul-regular";
}

.fundo-escrita-card {
  background-color: white;
  border-radius: 15px;
  padding: 5%;
  color: #a71f38;
}

.card-item {
  overflow: hidden;
}

.card-img:hover {
  transform: scale(1.1);
  border-radius: 5px;
}

.v-icon.v-icon {
  font-size: 80px;
}
</style>
