<template>
  <div class="d-flex flex-column align-center">
    <div class="adiciona-button">
        <!--<v-col v-show="!adicionar" cols="12" class="my-2 align-center tam" style="padding:0px">
          <v-btn v-on:click="adicionarAgendamento" color="#98C238" dark>Adicionar Agendamento</v-btn>
        </v-col>-->
        <v-col cols="12" class="align-center" style="padding:0px">
          <h1 class="mb-3">Atendimentos</h1>
        </v-col>
    </div>
    <div v-if="adicionar" style="width:85%">
      <v-row >
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            disabled
              label="Responsável"
              v-model="editedItem.representante_nome"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
              disabled
              label="Aluno"
              v-model="editedItem.aluno"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-select
              :items="atendimentos"
              label="Tipo do atendimento"
              item-text="nome"
              item-value="codigo"
              v-model="editedItem.atendimento_id"
              solo
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-select
              :items="statusAtendimento"
              label="Status atendimento"
              item-text="nome"
              item-value="codigo"
              v-model="editedItem.status_id"
              solo
          ></v-select>
        </v-col>
      </v-row>
        <v-col cols="12" sm="12" md="12">
          <DefaultDatePicker
          :label="'Data do atendimento'"
          :data="(editedItem.agendado_em == '')? new Date(): editedItem.agendado_em"
          @update:returnDate="editedItem.agendado_em = $event"/>
        </v-col>
        <v-col v-show="editedItem.status_id == 2" cols="12" sm="12" md="12">
          <v-text-field
            label="Hora do atendimento"
            v-model="editedItem.hora">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-textarea
              name="input-7-1"
              label="Adicione texto referente as observações"
              v-model="editedItem.observacoes"
          ></v-textarea>
        </v-col>
      <v-row class="mb-5">
        <v-col cols="12" sm="6" md="6">
          <DefaultButton :cor="1" :text="'Cancelar'" @click.native="adicionarAgendamento()"></DefaultButton>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <DefaultButton :text="(editar == true)?'Salvar Edição':'Adicionar'"
                         :disable="(editedItem.atendimento_id != null && editedItem.status_id != null)?false:true"
                         @click.native="salvarNovoAgendamento()"></DefaultButton>
        </v-col>
      </v-row>
    </div>
    <v-data-table
        :headers="headers"
        :items="agendamentos"
        :page.sync="page"
        :items-per-page="12"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="codigo"
        show-expand
        v-show="!adicionar"
        hide-default-footer
        class="elevation-1 estilo-tabela"
        @page-count="pageCount = $event"
        :loading="loading"
        loading-text="Carregando...">

      <template v-slot:item.nome_atendimento="{ item }" style="width:100%">
        <v-icon class="icon_tipo">
          fa-{{item.icone}}
        </v-icon>
        <span style="display: inline; padding-left:10px">{{item.nome_atendimento}}</span>
      </template>

      <template v-slot:item.status_atendimento="{item}">
        <v-chip :color="getColor(item.status_atendimento)" dark class="status">
          {{ item.status_atendimento }}
        </v-chip>
      </template>

      <template v-slot:top>
        <v-dialog v-model="dialogDelete" max-width="600">
          <v-card>
            <v-card-title class="headline">Tem certeza que quer excluir esse agendamento?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <span v-if="item.observacoes != ''">{{item.observacoes}}</span>
          <span v-else>{{defaultItem.observacoes}}</span>
        </td>
      </template>

      <template v-slot:item.actions="{ item }">
        {{terminaLoading()}}
        <v-icon
            small
            class="mr-2 icon_acoes"
            @click="editItem(item)">
            mdi-pencil
        </v-icon>
        <v-icon
            small
            class="icon_acoes"
            @click="deleteItem(item)">
            mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <h4 class="mb-1 mt-1">Lista de agendamentos vazia</h4>
      </template>
    </v-data-table>
    <div v-show="!adicionar" class="text-center pt-2">
      <v-pagination
          class="pagination"
          v-model="page"
          :length="pageCount"
      ></v-pagination>
    </div>
    <DefaultDialog
        :dialog="dialog"
        :title="(editar != true)?'Adicionado com sucesso':'Editado com sucesso'"
        :text="(editar != true)?'Seu agendamento foi adicionado com sucesso.':'Seu agendamento foi editado com sucesso.'"
        @update:closeDialog="dialog = $event"
    />
  </div>
</template>

<script>
import axios from "../../services/axios";
import moment from "moment";


export default {
  name: 'AdmAgendamentos',
  components: {
    DefaultDocumentInput: () => import("@/components/DefaultComponents/DefaultDocumentInput"),
    DefaultButton: () => import("@/components/DefaultComponents/DefaultButton"),
    DefaultDatePicker: () => import("@/components/DefaultComponents/DefaultDatePicker"),
    DefaultDialog: () => import("@/components/DefaultComponents/DefaultDialog"),
  },
  data() {
    return {
      loading: false,
      expanded: [],
      singleExpand: true,
      dialog: false,
      dialogDelete: false,
      adicionar: false,
      editar: false,
      page: 1,
      pageCount: 0,
      headers: [
        {text: 'Tipo do Atendimento', sortable: true, value:'nome_atendimento',},
        {text: 'Responsável', value: 'representante_nome', sortable: false},
        {text: 'Aluno', value: 'estudante[0].name', sortable: false},
        {text: 'Status',value: 'status_atendimento', sortable: true,},
        {text: 'Data do pedido', value: 'data_agendamento', sortable: true,},
        {text: 'Data Agendada', value: 'agendado_em', sortable: true,},
        {text: 'Hora Agendada', value: 'hora', sortable: false,},
        {text: 'Observações', value: 'data-table-expand',},
        {text: 'Ações', value: 'actions', sortable: false},
      ],
      agendamentos: [],
      atendimentos: [],
      statusAtendimento: [],
      editedIndex: -1,
      editedItem: {
        observacoes: '',
        agendado_em: '',
        hora: '',
        atendimento_id: null,
        codigo: -1,
        status_id: null,
        aluno: '',
        representante_nome: '',
      },
      defaultItem: {
        observacoes: 'Sem observações',
        agendado_em: '',
        hora: '',
        atendimento_id: null,
        codigo: -1,
        status_id: null,
        aluno: '',
        representante_nome: '',
      },
    }
  },
  mounted() {
    this.inicializa()
  },
  methods: {
    inicializa() {
      this.loading = true,
      axios
        .get("/api/agendamentos")
        .then((response) => {
          this.agendamentos = response.data.data.map((agendamento) => ({
            ...agendamento, // Association, same as Object.assign
            agendado_em:
              agendamento.agendado_em == null || agendamento.agendado_em == ""
                ? "--/--/----"
                : this.formataData(agendamento.agendado_em),
            data_agendamento:
              agendamento.data_agendamento == null ||
              agendamento.data_agendamento == ""
                ? "--/--/----"
                : this.formataData(agendamento.data_agendamento),
          }));
        if(response.data.data.length == 0)this.terminaLoading();
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get("/api/tipo_atendimento")
        .then((response) => {
          this.atendimentos = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get("/api/status_agendamento")
        .then((response) => {
          this.statusAtendimento = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formataData(data) {
      return moment(data).format('DD/MM/YYYY')
    },
    terminaLoading(){
      this.loading = false
    },
    adicionarAgendamento() {
      this.adicionar = !this.adicionar;
      if (this.adicionar == false) {

        this.editedtItem = {
          observacoes: '',
          agendado_em: '',
          hora: '',
          atendimento_id: null,
          codigo: -1,
          status_id: null,
          aluno: '',
          representante_nome: '',
        }
      }
      if (this.editar == true) {
        this.editar = false
        this.editedtItem = {
          observacoes: '',
          agendado_em: '',
          hora: '',
          atendimento_id: null,
          codigo: -1,
          status_id: null,
          aluno: '',
          representante_nome: '',
        }
      }
    },
    salvarNovoAgendamento() {
      if (this.editedItem.atendimento_id != null && this.editedItem.status_id != null) {
        this.dialog = true
        const dados = {
          "scheduled_at": moment(this.editedItem.agendado_em).format("YYYY-MM-DD"),
          "hour": this.editedItem.hora,
          "schedule_status_id": this.editedItem.status_id,
          "services_id": this.editedItem.atendimento_id,
          "comments": this.editedItem.observacoes,
          "id": this.editedItem.codigo,
        };
        if (this.editar != true) {
          axios
            .post(
              "/api/agendamentos",
              dados,
              {headers: {},}
            )
            .then(() => {
              this.inicializa();
            })
            .catch((error) => {
              console.log(error);
            });
            this.adicionar = !this.adicionar;
        } else {
          axios
            .put("/api/agendamentos/" +
              this.editedItem.codigo,
              dados,
              {headers: {},}
            )
            .then(() => {
              this.inicializa();
            })
            .catch((error) => {
              console.log(error);
            });
          this.adicionar = !this.adicionar;
        }
        this.defaultItem = {
          observacoes: '',
          agendado_em: '',
          hora: '',
          atendimento_id: null,
          codigo: -1,
          status_id: null,
          aluno: '',
          representante_nome: '',
        };
      }
    },
    deleteItem(item) {
      this.editedIndex = this.agendamentos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    editItem(item) {
      if (this.adicionar == false) this.adicionar = true
      this.editedItem.agendado_em = item.agendado_em
      this.editedItem.hora = item.hora
      this.editedItem.atendimento_id = item.atendimento_id
      this.editedItem.status_id = item.status_id
      this.editedItem.codigo = item.codigo
      this.editedItem.observacoes = item.observacoes
      this.editedItem.aluno = item.estudante[0].name
      this.editedItem.representante_nome = item.representante_nome
      this.editar = true
    },
    deleteItemConfirm() {
      axios
          .delete(
              "/api/agendamentos/" +
              this.agendamentos[this.editedIndex].codigo
          )
          .then(() => {
          })
          .catch((error) => {
            console.log(error);
          });
      this.agendamentos.splice(this.editedIndex, 1)
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    getColor (status_atendimento) {
        if (status_atendimento == 'Cancelado') return 'red'
        else if (status_atendimento == 'Aguardando aprovação') return 'orange'
        else return 'green'
      },
  },
}
</script>

<style>
.estilo-tabela {
  width: 100% !important;
}

.pagination > ul > li > button > i {
  font-size: 45px !important;
}

.status{
  width:100%;
  justify-content: center
}

.mdi-chevron-down{
  color: #ef4056 !important;
  height: 25px;
  width: 25px;
  font-size: 50px !important;
}

.icon_tipo{
  color: #00000067 !important;
  font-size: 30px !important;
  display: inline !important;
}

</style>
