<template>
  <div class="d-flex flex-column align-center">
    <div class="adiciona-button">
        <v-col v-show="!adicionar" cols="12" class="my-2 align-center tam" style="padding:0px">
          <v-btn v-on:click="adicionarcalendario" color="#98C238" dark>Adicionar calendário</v-btn>
        </v-col>
        <v-col cols="12" class="align-center" style="padding:0px">
          <h1 class="mb-3">Calendário</h1>
        </v-col>
    </div>
    <div v-if="adicionar" style="width:85%">
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <DefaultDocumentInput
              text="Anexar imagem"
              :required="true"
              type="calendario"
              module="editedItem.imagem"
              reqText="Selecione o arquivo"
              @update:returnFile="editedItem.imagem = $event"
          />
        </v-col>
      </v-row>
      <v-row class="mb-5">
        <v-col cols="12" sm="6" md="6">
          <DefaultButton :cor="1" :text="'Cancelar'" @click.native="adicionarcalendario()"></DefaultButton>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <DefaultButton :text="(editar == true)?'Salvar Edição':'Adicionar'"
                         :disable="(this.editedItem.imagem != null)?false:true"
                         @click.native="salvarNovocalendario()"></DefaultButton>
        </v-col>
      </v-row>
    </div>
    <DefaultDialog
        :dialog="dialog"
        :title="(editar != true)?'Adicionado com sucesso':'Editado com sucesso'"
        :text="(editar != true)?'Seu calendario foi adicionado com sucesso.':'Seu calendário foi editado com sucesso.'"
        @update:closeDialog="dialog = $event"
    />
    <div>
     <v-row v-if="abreImagem">
        <v-col cols="12" sm="12" md="12">
          <img :src="'data:image/png;base64,' + calendario[0].imagem"></img>
        </v-col>
      </v-row>
  </div> 
  </div>
  

</template>

<script>
import axios from "../../services/axios";

export default {
  name: 'AddCalendario',
  components: {
    DefaultDocumentInput: () => import("@/components/DefaultComponents/DefaultDocumentInput"),
    DefaultButton: () => import("@/components/DefaultComponents/DefaultButton"),
    DefaultDialog: () => import("@/components/DefaultComponents/DefaultDialog"),
  },
  data() {
    return {
      abreImagem: false,
      dialog: false,
      dialogDelete: false,
      adicionar: false,
      editar: false,
      calendario: [],
      editedIndex: -1,
      editedItem: {
        codigo: 1,
        imagem: null,
      },
    }
  },
  mounted() {
    this.inicializa()
  },
  methods: {
    inicializa() {
      axios
        .get("/api/calendarios")
        .then((response) => {
          this.calendario = response.data.data;
          this.abreImagem = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    adicionarcalendario() {
      this.adicionar = !this.adicionar;
      if (this.editar == true) this.editar = false
      this.editedItem= {
        codigo: 1,
        imagem: null,
      };
    },
    salvarNovocalendario() {
      if (this.editedItem.imagem != null) {
        this.dialog = true
        const dados = {
          "id": this.editedItem.codigo,
          "imagem": this.editedItem.imagem[0].base64,
        };

          axios
            .put(
              "/api/calendarios/" +
                this.editedItem.codigo,
              dados,
            )
            .then(() => {
              this.inicializa();
            })
            .catch((error) => {
              console.log(error);
            });
        this.adicionar = !this.adicionar;
      }
    },
  },
}
</script>