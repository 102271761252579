<template>
  <div>
    <div class="d-flex align-center mt-15 " style="justify-content: center">
      <v-btn class="mx-2 mr-5" fab dark color="#EF4056" @click="voltar()">
        <v-icon dark>
          mdi-arrow-left-thick
        </v-icon>
      </v-btn>
      <h1>Albuns</h1>
    </div>

    <default-date-input></default-date-input>

    <div class="mt-16 flex">
      <card-album
        v-for="(album, i) in albuns"
        :key="i"
        :title="album.nome"
        :data="album.data_evento"
        :imagem="album.capa"
        @click.native="abreAlbum(album)"
      ></card-album>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Albuns",
  components: {
    DefaultDateInput: () =>
      import("@/components/DefaultComponents/DefaultDateInput.vue"),
    CardAlbum: () => import("@/components/DefaultComponents/CardAlbum.vue"),
  },
  mounted() {
    this.inicializa();
  },
  data() {
    return {
      albuns: [],
    };
  },
  methods: {
    voltar() {
      this.$router.back();
    },
    inicializa() {
      axios
        .get("/albuns")
        .then((response) => {
          console.log("albuns", response.data.data);
          this.albuns = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    abreAlbum(album) {
      window.location = album.link;
    },
  },
};
</script>

<style>
.flex {
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto;
}
</style>
